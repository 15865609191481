import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the thunk
export const fetchStreamersStatus = createAsyncThunk(
  "streamers/fetchStatus",
  async () => {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/misc/streamers-status`
    );
    return response.data.data;
  }
);

export const selectIsAnyStreamerLive = (state) =>
  state.streamers.some((streamer) => streamer.isStreaming);

export const selectLiveStreamer = (state) =>
  state.streamers.find((streamer) => streamer.isStreaming);

// Define the slice
const streamersSlice = createSlice({
  name: "streamers",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStreamersStatus.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default streamersSlice.reducer;
