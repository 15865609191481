import React, { useState, useEffect } from 'react';
import Link from 'next/link';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent !== 'true') {
      setIsVisible(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className='fixed inset-x-0 bottom-0 z-[1000] flex flex-col justify-between gap-x-8 gap-y-4 bg-navTransparent/50 p-6 ring-1 ring-gray-900/10 backdrop-blur-lg md:flex-row md:items-center lg:px-8'>
      <p className='max-w-4xl text-sm leading-6 text-text'>
        Bu site, hizmetlerimizi sunmamıza yardımcı olmak ve iyileştirmek için
        zorunlu çerezler kullanır. Daha fazla bilgi için{' '}
        <Link href='/tos#cookies' className='font-semibold text-main'>
          Çerez Politikamızı
        </Link>{' '}
        inceleyebilirsiniz.
      </p>
      <div className='flex flex-none items-center gap-x-5'>
        <button
          type='button'
          className='buttonGradientBlue w-full px-4 md:w-auto'
          onClick={handleConsent}
        >
          Kabul Et
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
