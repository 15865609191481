import React, { useState } from 'react';
import Link from 'next/link';
import {
  IoSettingsSharp,
  IoChatbubble,
  IoTicket,
  IoPerson,
  IoMedal,
} from 'react-icons/io5';
import { LuLink } from 'react-icons/lu';
import { SiGithubsponsors } from 'react-icons/si';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const ProfileNav = () => {
  const router = useRouter();
  const route = router.pathname;
  const messages = useSelector((state) => state.messages);

  const [activeLink, setActiveLink] = useState(route);

  const pageLinks = [
    {
      name: 'Profil',
      path: '/profile',
      icon: <IoPerson />,
    },
    {
      name: 'Sponsorlarım',
      path: '/sponsorlarim',
      icon: <SiGithubsponsors />,
    },
    {
      name: 'Bağlantılar',
      path: '/connections',
      icon: <LuLink />,
    },
    {
      name: 'Çekilişlerim',
      path: '/cekilislerim',
      icon: <IoTicket />,
    },
    {
      name: 'Mesajlar',
      path: '/messages',
      icon: <IoChatbubble />,
    },
  ];

  return (
    <nav className='no-scrollbar backgradientDark h-[350px] w-full overflow-hidden rounded-lg p-4'>
      <div className='no-scrollbar flex h-full flex-col'>
        {pageLinks.map((link) => (
          <Link
            href={link.path}
            key={link.name}
            className={`${
              activeLink === link.path
                ? 'bg-bgDarkest text-white'
                : 'text-steelBlue hover:text-white'
            } flex grow items-center gap-2 rounded-lg px-4 py-4 font-medium transition md:py-0`}
            onClick={() => setActiveLink(link.path)}
          >
            <span className='md:hidden lg:block'>{link.icon}</span> {link.name}
            {link.path === '/messages' && (
              <div className='bg-accentPink mb-4 hidden h-2 w-2 -translate-x-2 rounded-full'></div>
            )}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default ProfileNav;
export const pageLinks = [
  {
    name: 'Profil',
    path: '/profile',
    icon: <IoPerson />,
  },
  {
    name: 'Sponsorlarım',
    path: '/sponsorlarim',
    icon: <SiGithubsponsors />,
  },
  {
    name: 'Bağlantılar',
    path: '/connections',
    icon: <LuLink />,
  },
  {
    name: 'Çekilişlerim',
    path: '/cekilislerim',
    icon: <IoTicket />,
  },
  {
    name: 'Mesajlar',
    path: '/messages',
    icon: <IoChatbubble />,
  },
];
