import React, { useState, useEffect } from 'react';
import SelectComponent from '../FormComponents/SelectComponent';
import AuthButton from './NavbarComponents/AuthButton';

const TopNav = ({ style }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) setScrolled(true);
      else setScrolled(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`md:max-w-screen sticky top-0 z-[10000] mx-auto flex h-14 items-center justify-center rounded-lg px-1 backdrop-blur-xl transition-all duration-300 ease-in-out tiny:justify-between md:px-4 ${
        scrolled ? 'bg-background/80 px-6' : 'w-full bg-transparent p-0'
      }`}
      style={{
        ...style,
        transition:
          'background-color 300ms ease-in-out, padding 300ms ease-in-out',
      }}
    >
      <AuthButton />
    </div>
  );
};

export default TopNav;
