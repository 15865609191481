import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import chatReducer from "./chatSlice";
import userReducer from "./userSlice";
import modalReducer from "./modalSlice";
import isMobileReducer from "./isMobileSlice";
import isWideReducer from "./isWideSlice";
import prizesReducer from "./prizesSlice";
import streamersReducer from "./streamersSlice";
import messagesReducer from "./messagesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
    user: userReducer,
    prizes: prizesReducer,
    modal: modalReducer,
    isMobile: isMobileReducer,
    isWide: isWideReducer,
    streamers: streamersReducer,
    messages: messagesReducer,
  },
});
