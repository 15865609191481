import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const ProfileImage = ({ src, alt }) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={38}
      height={38}
      className='mb-1 mr-1 translate-y-[2px] rounded-lg border-2 border-accentBlue shadow-sm shadow-accentBlue drop-shadow-md'
    />
  );
};

export default ProfileImage;
