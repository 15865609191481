import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import Image from 'next/image';
import profile from '../../../public/profile.png';
import { CirclePicker } from 'react-color';
import { RiVipDiamondFill } from 'react-icons/ri';
import { BiShieldQuarter } from 'react-icons/bi';
import { PiWrenchFill } from 'react-icons/pi';

const ChatSettings = ({ isSettingsOpen, setIsSettingsOpen, user }) => {
  const [textColor, setTextColor] = useState('#F0F0F0');
  const [boxColor, setBoxColor] = useState('#051731');

  useEffect(() => {
    setTextColor(user.chatSettings?.textColor || '#F0F0F0');
    setBoxColor(user.chatSettings?.boxColor || '#051731');
  }, [user.chatSettings]);

  const handleTextColorChange = (color) => {
    setTextColor(color.hex);
  };

  const handleBoxColorChange = (color) => {
    setBoxColor(color.hex + '20');
  };

  const handleSettingsSave = () => {
    const payload = {
      textColor,
      boxColor,
    };

    axios
      .put(
        `${process.env.NEXT_PUBLIC_API_URL}/user/update-chat-settings`,
        payload,
        { withCredentials: true }
      )
      .then((response) => {
        toast.success(response.data.msg);
      })
      .catch((error) => {
        toast.error(error.response.data.msg);
      })
      // finally set the isSettingsOpen state to false
      .finally(() => {
        setIsSettingsOpen(false);
      });
  };

  return (
    <AnimatePresence>
      {isSettingsOpen && (
        <motion.div
          className='absolute bottom-24 flex w-full flex-col overflow-hidden rounded-lg bg-background/50 p-2 backdrop-blur-lg'
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
        >
          <div className='mb-4 flex h-fit justify-between'>
            <h3 className='pointer-events-none ml-2 font-semibold text-text'>
              Sohbet Ayarları
            </h3>
            <IoClose
              className='cursor-pointer text-lg text-text hover:text-main'
              onClick={() => setIsSettingsOpen(false)}
            />
          </div>

          <div className='mb-2 flex items-start gap-2 p-1'>
            <div>
              <Image
                src={profile}
                className='h-8 w-8 translate-y-[2px] rounded-lg border-2 border-accentBlue shadow-sm shadow-accentBlue drop-shadow-md'
                alt='Profile'
              />
            </div>
            <div
              className='wrap mr-2 flex-1 overflow-hidden rounded-md p-2'
              style={{ backgroundColor: boxColor }}
            >
              <div className='flex items-center justify-between'>
                <p className='mb-2 text-xs font-bold text-textGrey'>
                  {user.username}
                </p>
              </div>
              <p
                className='break-words text-sm font-semibold'
                style={{ color: textColor }}
              >
                Mesajınız böyle gözükür.
              </p>
            </div>
          </div>
          <div className='flex flex-col items-center justify-center gap-4'>
            <div>
              <h3 className='text-xs font-bold leading-relaxed text-text'>
                Mesaj Rengi
              </h3>
              <CirclePicker
                colors={[
                  '#37D67A',
                  '#2CCCE4',
                  '#dce775',
                  '#ff8a65',
                  '#ba68c8',
                  '#f0f0f0',
                ]}
                circleSize={20}
                onChange={handleTextColorChange}
              />
            </div>
            <div>
              <h3 className='text-xs font-bold leading-relaxed text-text'>
                Kutu Rengi
              </h3>
              <CirclePicker
                colors={[
                  '#37D67A',
                  '#2CCCE4',
                  '#dce775',
                  '#ff8a65',
                  '#ba68c8',
                  '#f0f0f0',
                ]}
                circleSize={20}
                onChange={handleBoxColorChange}
              />
            </div>
          </div>
          <div
            onClick={handleSettingsSave}
            className='mt-6 flex w-24 cursor-pointer items-center justify-center self-end rounded-lg bg-gradient-to-b from-[#F82781] to-[#FF4093] py-1 font-bold text-white transition duration-300 hover:-translate-y-[0.05rem] hover:shadow-lg hover:shadow-accentPink/20'
          >
            Kaydet
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ChatSettings;
