export const customEmojis = [
  {
    names: ['40OU', '40ou'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/40OU.gif',
    id: '40OU',
  },
  {
    names: ['aglamaOyna', 'aglamaoyna'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/aglamaOyna.gif',
    id: 'aglamaOyna',
  },
  {
    names: ['AMK', 'amk'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/AMK.gif',
    id: 'AMK',
  },
  {
    names: ['bacongo', 'bacongo'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/bacongo.gif',
    id: 'bacongo',
  },
  {
    names: ['xjban', 'xjBAN'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/ban.gif',
    id: 'xjban',
  },
  {
    names: ['bananeMaymun', 'bananemaymun'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/bananeMaymun.gif',
    id: 'bananeMaymun',
  },
  {
    names: ['BASED', 'based'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/BASED.gif',
    id: 'BASED',
  },
  {
    names: ['Burn', 'burn'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/Burn.gif',
    id: 'Burn',
  },
  {
    names: ['burnPepe', 'burnpepe'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/burnPepe.gif',
    id: 'burnPepe',
  },
  {
    names: ['bussers', 'bussers'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/bussers.gif',
    id: 'bussers',
  },
  {
    names: ['catNope', 'catnope'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/catNope.gif',
    id: 'catNope',
  },
  {
    names: ['cenki_gif', 'cenki_gif'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/cenki_gif.gif',
    id: 'cenki_gif',
  },
  {
    names: ['centilmenMaymun', 'centilmenmaymun'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/centilmenMaymun.gif',
    id: 'centilmenMaymun',
  },
  {
    names: ['CHEERS', 'cheers'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/CHEERS.png',
    id: 'CHEERS',
  },
  {
    names: ['customEmojis', 'customemojis'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/customEmojis.json',
    id: 'customEmojis',
  },
  {
    names: ['dagilin', 'dagilin'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/dagilin.gif',
    id: 'dagilin',
  },
  {
    names: ['dans', 'dans'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/dans.gif',
    id: 'dans',
  },
  {
    names: ['dilMaymun', 'dilmaymun'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/dilMaymun.gif',
    id: 'dilMaymun',
  },
  {
    names: ['dino', 'dino'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/dino.gif',
    id: 'dino',
  },
  {
    names: ['dinoDans', 'dinodans'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/dinoDans.gif',
    id: 'dinoDans',
  },
  {
    names: ['eeee', 'eeee'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/eeee.png',
    id: 'eeee',
  },
  {
    names: ['elpepehacker', 'elpepehacker'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/elpepehacker.gif',
    id: 'elpepehacker',
  },
  {
    names: ['feelsWeakMan', 'feelsweakman'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/feelsWeakMan.gif',
    id: 'feelsWeakMan',
  },
  {
    names: ['fezfezfez', 'fezfezfez'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/fezfezfez.gif',
    id: 'fezfezfez',
  },
  {
    names: ['hehe', 'hehe'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/hehe.gif',
    id: 'hehe',
  },
  {
    names: ['hmmm', 'hmmm'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/hmmm.gif',
    id: 'hmmm',
  },
  {
    names: ['homerSaklan', 'homersaklan'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/homerSaklan.gif',
    id: 'homerSaklan',
  },
  {
    names: ['huh', 'huh'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/huh.png',
    id: 'huh',
  },
  {
    names: ['iyiUykular', 'iyiuykular'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/iyiUykular.gif',
    id: 'iyiUykular',
  },
  {
    names: ['KEKW', 'kekw'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/KEKW.gif',
    id: 'KEKW',
  },
  {
    names: ['kekw', 'kekw'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/kekw.png',
    id: 'kekw',
  },
  {
    names: ['komikDegil', 'komikdegil'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/komikDegil.gif',
    id: 'komikDegil',
  },
  {
    names: ['LoOh', 'looh'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/LoOh.gif',
    id: 'LoOh',
  },
  {
    names: [
      'lpschmo\u2560\u00eawe-lpschmiedetv',
      'lpschmo\u2560\u00eawe-lpschmiedetv',
    ],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/lpschmo\u2560\u00eawe-lpschmiedetv.gif',
    id: 'lpschmo\u2560\u00eawe-lpschmiedetv',
  },
  {
    names: ['meme', 'meme'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/meme.png',
    id: 'meme',
  },
  {
    names: ['memes', 'memes'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/memes.png',
    id: 'memes',
  },
  {
    names: ['metalciSolucan', 'metalcisolucan'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/metalciSolucan.gif',
    id: 'metalciSolucan',
  },
  {
    names: ['mike', 'mike'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/mike.png',
    id: 'mike',
  },
  {
    names: ['monka-w', 'monka-w'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/monka-w.gif',
    id: 'monka-w',
  },
  {
    names: ['monkadrive-monka', 'monkadrive-monka'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/monkadrive-monka.gif',
    id: 'monkadrive-monka',
  },
  {
    names: ['monke', 'monke'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/monke.gif',
    id: 'monke',
  },
  {
    names: ['mutluMaymun', 'mutlumaymun'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/mutluMaymun.gif',
    id: 'mutluMaymun',
  },
  {
    names: ['xjne', 'xjNE'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/NE.gif',
    id: 'xjne',
  },
  {
    names: ['nopers-twitch', 'nopers-twitch'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/nopers-twitch.gif',
    id: 'nopers-twitch',
  },
  {
    names: ['oNR', 'onr'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/oNR.gif',
    id: 'oNR',
  },
  {
    names: ['ordegaming', 'ordegaming'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/ordegaming.gif',
    id: 'ordegaming',
  },
  {
    names: ['patrickGa', 'patrickga'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/patrickGa.gif',
    id: 'patrickGa',
  },
  {
    names: ['peepo-caught-ayaya', 'peepo-caught-ayaya'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/peepo-caught-ayaya.gif',
    id: 'peepo-caught-ayaya',
  },
  {
    names: ['peepo-leave-leaving', 'peepo-leave-leaving'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/peepo-leave-leaving.gif',
    id: 'peepo-leave-leaving',
  },
  {
    names: ['peepo-peepo-run', 'peepo-peepo-run'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/peepo-peepo-run.gif',
    id: 'peepo-peepo-run',
  },
  {
    names: ['peepobye-twitch', 'peepobye-twitch'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/peepobye-twitch.gif',
    id: 'peepobye-twitch',
  },
  {
    names: ['pepe-pepe-the-frog', 'pepe-pepe-the-frog'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepe-pepe-the-frog.gif',
    id: 'pepe-pepe-the-frog',
  },
  {
    names: ['pepecool', 'pepecool'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepecool.png',
    id: 'pepecool',
  },
  {
    names: ['Pepecry', 'pepecry'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/Pepecry.png',
    id: 'Pepecry',
  },
  {
    names: ['pepegaKart', 'pepegakart'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepegaKart.gif',
    id: 'pepegaKart',
  },
  {
    names: ['pepeHa', 'pepeha'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepeHa.gif',
    id: 'pepeHa',
  },
  {
    names: ['pepelaugh', 'pepelaugh'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepelaugh.png',
    id: 'pepelaugh',
  },
  {
    names: ['pepelaughing-pepelaugh', 'pepelaughing-pepelaugh'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepelaughing-pepelaugh.gif',
    id: 'pepelaughing-pepelaugh',
  },
  {
    names: ['pepelove', 'pepelove'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepelove.png',
    id: 'pepelove',
  },
  {
    names: ['pepethink', 'pepethink'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepethink.png',
    id: 'pepethink',
  },
  {
    names: ['pepoclap', 'pepoclap'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pepoclap.gif',
    id: 'pepoclap',
  },
  {
    names: ['pinkguy', 'pinkguy'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pinkguy.jpg',
    id: 'pinkguy',
  },
  {
    names: ['pog', 'pog'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/pog.gif',
    id: 'pog',
  },
  {
    names: ['popcat-pop', 'popcat-pop'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/popcat-pop.gif',
    id: 'popcat-pop',
  },
  {
    names: ['popping-head-pop-up', 'popping-head-pop-up'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/popping-head-pop-up.gif',
    id: 'popping-head-pop-up',
  },
  {
    names: ['quby-cute', 'quby-cute'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/quby-cute.gif',
    id: 'quby-cute',
  },
  {
    names: ['quby-line-sticker', 'quby-line-sticker'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/quby-line-sticker.gif',
    id: 'quby-line-sticker',
  },
  {
    names: ['rage', 'rage'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/rage.gif',
    id: 'rage',
  },
  {
    names: ['S5r', 's5r'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/S5r.gif',
    id: 'S5r',
  },
  {
    names: ['sadcat', 'sadcat'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/sadcat.png',
    id: 'sadcat',
  },
  {
    names: ['samusDans', 'samusdans'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/samusDans.gif',
    id: 'samusDans',
  },
  {
    names: ['saskinMaymun', 'saskinmaymun'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/saskinMaymun.gif',
    id: 'saskinMaymun',
  },
  {
    names: ['shaq', 'shaq'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/shaq.gif',
    id: 'shaq',
  },
  {
    names: ['siSenor', 'sisenor'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/siSenor.gif',
    id: 'siSenor',
  },
  {
    names: ['sticker_1', 'sticker_1'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/sticker_1.png',
    id: 'sticker_1',
  },
  {
    names: ['sticker_3', 'sticker_3'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/sticker_3.png',
    id: 'sticker_3',
  },
  {
    names: ['triggeredPepe', 'triggeredpepe'],
    imgUrl:
      'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/triggeredPepe.gif',
    id: 'triggeredPepe',
  },
  {
    names: ['uykuluMaymun', 'uykulumaymun'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/uykuluMaymun.gif',
    id: 'uykuluMaymun',
  },
  {
    names: ['whatspoppin', 'whatspoppin'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/whatspoppin.gif',
    id: 'whatspoppin',
  },
  {
    names: ['wormin', 'wormin'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/wormin.gif',
    id: 'wormin',
  },
  {
    names: ['Wow', 'wow'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/Wow.gif',
    id: 'Wow',
  },
  {
    names: ['WTF', 'wtf'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/WTF.gif',
    id: 'WTF',
  },
  {
    names: ['ZBqn', 'zbqn'],
    imgUrl: 'https://d38ezbe56qv4qs.cloudfront.net/img/emotes/ZBqn.gif',
    id: 'ZBqn',
  },
];

export const convertCustomEmojis = (message) => {
  // Enhanced pattern to more reliably identify HTML tags
  const harmfulTagPattern = /<\s*\/?\s*([a-zA-Z]+)[\s\S]*?>/g;

  // Function to escape detected HTML tags
  const escapeHtmlTags = (text) => {
    return text.replace(harmfulTagPattern, (match, tagName) => {
      // Only escape if the tag is in a list of tags known to be dangerous or unwanted
      const harmfulTags = [
        'script',
        'img',
        'iframe',
        'embed',
        'link',
        'meta',
        'object',
        'style',
      ];
      if (harmfulTags.includes(tagName.toLowerCase())) {
        // Replace < and > with their HTML entities
        return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
      }
      return match; // Return the match unchanged if not harmful
    });
  };

  // Process for emojis and escaping
  return message
    .split(/(\s+)/)
    .map((fragment) => {
      const emoji = customEmojis.find((emoji) =>
        emoji.names.includes(fragment)
      );
      if (emoji) {
        return `<img class="ct-em" src="${emoji.imgUrl}" alt="${fragment}" data-emoji-id="${emoji.id}" />`;
      }
      return escapeHtmlTags(fragment); // Apply tag escaping
    })
    .join('');
};
