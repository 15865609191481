const emojiCategories = [
  {
    category: 'custom',
    name: 'Özel Emojiler',
  },
  {
    category: 'suggested',
    name: 'Son Kullanılanlar',
  },
  {
    category: 'smileys_people',
    name: 'Yüz İfadeleri',
  },
  {
    category: 'animals_nature',
    name: 'Hayvan ve Doğa',
  },
  { category: 'travel_places', name: 'Gezi ve Tatil' },
  {
    category: 'food_drink',
    name: 'Yiyecek ve İçecek',
  },
  {
    category: 'flags',
    name: 'Bayraklar',
  },
]

export default emojiCategories
