import { createSlice } from "@reduxjs/toolkit";

const messagesSlice = createSlice({
  name: "messages",
  initialState: 0,
  reducers: {
    setMessages: (state, action) => {
      return action.payload;
    },
    decrement: (state) => {
      return state - 1;
    },
    increment: (state) => {
      return state + 1;
    },
  },
});

export const { setMessages, decrement, increment } = messagesSlice.actions;

export default messagesSlice.reducer;
