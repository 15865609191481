import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { IoChevronDownOutline } from 'react-icons/io5';

const SelectComponent = ({
  name,
  value,
  onChange,
  options,
  Icon = null,
  placeholder = 'Select an option...', // Default placeholder text
  required,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    setSelectedLabel(selectedOption ? selectedOption.label : '');
  }, [value, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectChange = (newValue, newLabel) => {
    onChange({ target: { name, value: newValue } });
    setSelectedLabel(newLabel);
    setIsOpen(false);
  };

  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className='relative' ref={dropdownRef}>
      <div
        className='text-powderBlue group flex cursor-pointer items-center rounded-lg transition'
        onClick={() => setIsOpen(!isOpen)}
      >
        {Icon && <Icon className='ml-2 h-5 w-5' />}
        <div className='w-3/4 flex-1 px-2 py-2'>
          {selectedLabel || (
            <span className='text-gray-400/50'>{placeholder}</span>
          )}
        </div>
        <IoChevronDownOutline className='mr-2 h-5 w-5 text-gray-400' />
      </div>
      {isOpen && (
        <motion.div
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={dropdownVariants}
          className='absolute z-50 mt-1 w-full rounded-md bg-navTransparent/50 shadow-lg backdrop-blur-md'
        >
          {options.map((option) => (
            <div
              key={option.value}
              className='cursor-pointer px-4 py-2 text-text transition-colors duration-150 ease-in-out hover:bg-navTransparent/20 hover:text-main'
              onClick={() => handleSelectChange(option.value, option.label)}
            >
              {option.label}
            </div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default SelectComponent;
