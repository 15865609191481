import Link from 'next/link';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { selectLiveStreamer } from '/helpers/streamersSlice';
import { BsRecordFill } from 'react-icons/bs';

const Logo = () => {
  const liveStreamer = useSelector(selectLiveStreamer);
  return (
    <>
      <Link href='/' className='group self-center'>
        <Image
          src='https://d38ezbe56qv4qs.cloudfront.net/img/test/icons/xc-mobile-logo.png'
          width={40}
          height={40}
          alt='XJackpots Logo'
          unoptimized={true}
          quality={100}
        />
      </Link>
      {liveStreamer && (
        <Link
          href={liveStreamer.streamLink || liveStreamer.channelLink}
          target='_blank'
          rel='noopener noreferrer'
          className='absolute left-[75px] mt-[2px] text-text md:static'
        >
          <div className='flex items-center gap-[2px] text-red-600'>
            <div className='relative mr-[2px] flex h-3 w-3'>
              <BsRecordFill className='absolute inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-75' />
              <BsRecordFill className='relative inline-flex h-3 w-3 rounded-full bg-red-600' />
            </div>
            <div className='text-sm font-semibold uppercase tracking-wider'>
              Yayın
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default Logo;
