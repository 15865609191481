import '../styles/globals.css';
import NProgress from 'nprogress';
import '../styles/nprogress.css';
import '../styles/profile.css';
import '../styles/tables.css';
import '../styles/forms.css';
import '../styles/btn.css';
import '../styles/emojiPicker.css';
import { Provider } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';
import { store } from '/helpers/store';
import Footer from '/components/_appComponents/Footer';
import Navbar from '/components/_appComponents/Navbar';
import { GeistSans } from 'geist/font/sans';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import CookieConsent from '/components/_appComponents/CookieConsent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logIn, logOut } from '/helpers/authSlice';
import { setIsMobile } from '/helpers/isMobileSlice';
import { setIsWide } from '/helpers/isWideSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '/helpers/userSlice';
import { setGamdomLeaderboardPrizes } from '/helpers/prizesSlice';
import Chatroom from '/components/_appComponents/Chatroom';
import LoginModal from '/components/_appComponents/LoginModal';
import io from 'socket.io-client';
import SocketContext from '/context/SocketContext';
import Head from 'next/head';
import { Analytics } from '@vercel/analytics/react';
import Loading from '/components/_appComponents/Loading';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import CustomGrid from '/components/testComponents/CustomGrid';
import Banner from '/components/_appComponents/AdvertisementComponents/Banner';
import TopNav from '/components/_appComponents/TopNav';
import { hydrateChatState, setChatOpen } from '/helpers/chatSlice';

function App({ Component, pageProps }) {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const isExemptedPage = router.pathname === '/wanted-takip/sayac';
  // exempt pages that comes from /widgets...
  const widgetPage = router.pathname.startsWith('/widgets');
  const [socket, setSocket] = useState(null);
  const isChatOpen = useSelector((state) => state.chat.isOpen);
  const [mainWidth, setMainWidth] = useState('auto');

  const queryClient = new QueryClient();
  // const [queryClient] = useState(() => new QueryClient());

  // setting chat open for bigger screens and closing the chat for smaller ones
  useEffect(() => {
    if (!isChatOpen && window.innerWidth > 1535) dispatch(setChatOpen(true));
    else dispatch(setChatOpen(false));
  }, []);

  useEffect(() => {
    // Hydrate the chat state from localStorage
    const storedChatOpen = localStorage.getItem('isChatOpen');
    if (storedChatOpen !== null) {
      try {
        const parsedChatOpen = JSON.parse(storedChatOpen);
        console.log('Using stored state from localStorage:', parsedChatOpen);
        dispatch(hydrateChatState(parsedChatOpen)); // Hydrate the state globally
      } catch (error) {
        console.error('Error parsing stored chat open state:', error);
      }
    } else {
      // If no stored state, decide based on screen size
      if (typeof window !== 'undefined') {
        if (window.innerWidth < 1534) {
          console.log('Setting chat to closed due to small screen');
          dispatch(hydrateChatState(false));
          localStorage.setItem('isChatOpen', JSON.stringify(false));
        } else {
          console.log('Setting chat to open due to larger screen');
          dispatch(hydrateChatState(true));
          localStorage.setItem('isChatOpen', JSON.stringify(true));
        }
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const newSocket = io(process.env.NEXT_PUBLIC_API_URL, {
      transports: ['websocket', 'polling'],
      reconnectionDelay: 1000, // wait 1 second before the first reconnection attempt
      reconnectionDelayMax: 5000, // wait at most 5 seconds between reconnection attempts
    });

    let connectionErrorReported = false;
    let toastId = null;

    newSocket.on('connect_error', () => {
      if (!connectionErrorReported) {
        toastId = toast.error('Sunucu ile bağlantı kurulamadı.');
        connectionErrorReported = true;
      }
    });

    newSocket.on('reconnect_attempt', () => {
      toast.info('Sunucuya yeniden bağlanılıyor...');
    });

    newSocket.on('reconnecting', (attemptNumber) => {
      toast.info(`Sunucuya yeniden bağlanılıyor... (${attemptNumber})`);
    });

    newSocket.on('reconnect_error', () => {
      if (!connectionErrorReported) {
        toast.error('Sunucuya yeniden bağlanılamadı.');
        connectionErrorReported = true;
      }
    });

    newSocket.on('reconnect_failed', () => {
      if (!connectionErrorReported) {
        toast.error('Sunucuya yeniden bağlanılamadı.');
        connectionErrorReported = true;
      }
    });

    newSocket.on('reconnect', (attemptNumber) => {
      toast.success(`Sunucuya yeniden bağlandı. (${attemptNumber} denemede)`);
      if (toastId !== null) toast.dismiss(toastId);
      connectionErrorReported = false; // Reset the flag when a successful connection is made
    });

    newSocket.on('connect', () => {
      if (connectionErrorReported) {
        if (toastId !== null) toast.dismiss(toastId);
        toast.success('Sunucuya bağlantı sağlandı.');
      }
      connectionErrorReported = false;
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  const fetchAndSetUserInfo = async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/user/basic-info`,
        {
          credentials: 'include',
        }
      );
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setUserInfo({
            username: data.username,
            userID: data._id,
            userUID: data.userID,
            isAdmin: data.isAdmin,
            isBanned: data.isBanned,
            email: data.email,
            levelSystem: data.levelSystem,
            vipSystem: data.vipSystem,
            points: data.points,
            profileImg: data.profileImg,
            chatBadges: data.chatSettings.badges,
            chatColor: data.chatSettings.color,
            chatSettings: data.chatSettings,
            sponsorIDs: data.sponsorIDs,
            connections: data.connections,
            trxWallet: data.trxWallet,
            gamdomTrxWallet: data.gamdomTrxWallet,
            hasMigratedKick: data.hasMigratedKick,
            hasMigratedDosa: data.hasMigratedDosa,
          })
        );
      }
    } catch (error) {
      toast.error('Bir hata oluştu.');
    }
  };

  useEffect(() => {
    if (router.pathname.startsWith('/admin')) {
      const checkAdminStatus = async () => {
        try {
          const response = await fetch(
            `${process.env.NEXT_PUBLIC_API_URL}/user/check-admin`,
            {
              credentials: 'include',
            }
          );
          if (
            response.status === 401 ||
            response.status === 403 ||
            !response.ok
          ) {
            router.push('/');
          }
        } catch (error) {
          toast.error('Bir hata oluştu.');
          router.push('/');
        }
      };

      checkAdminStatus();
    }
  }, [router]);

  useEffect(() => {
    if (!socket) return; // Don't set up the listener if socket is not initialized yet

    let timeoutId;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // The tab is not active, start a grace period before disconnecting the socket
        timeoutId = setTimeout(
          () => {
            socket.disconnect();
          },
          1000 * 60 * 5
        ); // 5 minute grace period
      } else {
        // The tab is active, clear the grace period and reconnect the socket
        clearTimeout(timeoutId);
        socket.connect();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [socket]);

  useEffect(() => {
    const handleRouteStart = () => NProgress.start();

    const handleRouteDone = () => NProgress.done();

    NProgress.configure({
      showSpinner: false,
      trickleSpeed: 100,
      easing: 'ease',
      speed: 500,
      minimum: 0.3,
    });

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    const checkSessionStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/auth/check-session`,
          {
            credentials: 'include',
          }
        );
        if (response.status === 401) {
          dispatch(logOut());
        } else {
          const data = await response.json();
          if (data.isLoggedIn) {
            dispatch(logIn());
            fetchAndSetUserInfo(dispatch); // Fetch user info after login
          } else {
            dispatch(logOut());
          }
        }
      } catch (error) {
        toast.error('Bir hata oluştu.');
        dispatch(logOut());
      } finally {
        setIsLoading(false);
      }
    };

    checkSessionStatus();
    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);
    };
  }, [dispatch, router.events]);

  if (isLoading) {
    return (
      <>
        <Head>
          <title>xCerenays</title>
          <meta
            name='description'
            content='xCerenays ile eğlenceye adım at. Güvenilir sitelerde kazan!'
          ></meta>
          <meta
            name='keywords'
            content='xjackpots, xjackpots.com, xjackpots bonus, xjackpots bonus, xjackpots site, guvenilir siteler, bonuslu siteler, kumar izle, gates of olympus izle, '
          ></meta>
          <meta name='author' content='xJackpots'></meta>
          <meta name='robots' content='index, follow'></meta>
          <meta name='googlebot' content='index, follow'></meta>
          <meta name='google' content='notranslate'></meta>
          <meta name='format-detection' content='telephone=no'></meta>
          <meta name='theme-color' content='#1f2937'></meta>
          <meta name='msapplication-navbutton-color' content='#1f2937'></meta>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1'
          ></meta>
          <meta
            name='apple-mobile-web-app-status-bar-style'
            content='#1f2937'
          ></meta>
          <link rel='icon' href='/static/favicon.ico' />
          <link rel='apple-touch-icon' href='/static/favicon.ico'></link>
          <link rel='canonical' href='https://xjackpots40.com/'></link>
        </Head>
        <Loading />
      </>
    );
  }

  if (isExemptedPage) {
    return <Component {...pageProps} />;
  }

  if (widgetPage) {
    // remove every class from the body
    document.body.classList.remove('background-color');
    return (
      <div className='bg-transparent'>
        <Head>
          <title>xCerenays 💵 Anasayfa</title>
        </Head>
        <Component {...pageProps} />
      </div>
    );
  }

  // CELLS
  const bodyCells = [
    {
      colSpan: 12,
      rowSpan: 12,
      content: (
        <div className='flex px-2 md:gap-2'>
          {/* Left */}
          <Navbar />
          {/* <Banner /> */}
          {/* <div className='fixed left-0 right-0 top-0 z-[1000] w-full'>
            <TopNav />
          </div> */}
          {/* Middle (topnav & main) */}
          <div className='w-[60%] flex-1'>
            <TopNav />
            <main className='md:0 mx-auto mt-3 min-h-screen max-w-[1920px] shrink overflow-hidden rounded-lg bg-background bg-fixed bg-no-repeat'>
              <div className={`overflow-hidden`}>
                {/* pages */}
                <Component {...pageProps} />
                {/* others */}
                <Analytics />
                <LoginModal />
                <CookieConsent />
                <ToastContainer
                  toastStyle={{
                    backgroundColor: '#010100',
                    color: '#F9F6F0',
                  }}
                  style={{ zIndex: 99999999 }}
                  position='bottom-right'
                  autoClose={5000}
                  stacked
                  hideProgressBar={true}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </main>
          </div>
          {/* Right */}
          <Chatroom />
        </div>
      ),
      className: '',
    },
    { colSpan: 12, rowSpan: 1, content: <Footer />, className: '' },
  ];

  return (
    // PROVIDERS
    <QueryClientProvider client={queryClient}>
      <SocketContext.Provider value={socket}>
        <Provider store={store}>
          {/* HEAD TAGS */}
          <Head>
            <title>xCerenays</title>
            <meta
              name='description'
              content='xCerenays ile eğlenceye adım at. Güvenilir sitelerde kazan!'
            ></meta>
            <meta
              name='keywords'
              content='xjackpots, xjackpots.com, xjackpots bonus, xjackpots bonus, xjackpots site, guvenilir siteler, bonuslu siteler, kumar izle, gates of olympus izle, '
            ></meta>
            <meta name='author' content='xJackpots'></meta>
            <meta name='robots' content='index, follow'></meta>
            <meta name='googlebot' content='index, follow'></meta>
            <meta name='google' content='notranslate'></meta>
            <meta name='format-detection' content='telephone=no'></meta>
            <meta name='theme-color' content='#1f2937'></meta>
            <meta name='msapplication-navbutton-color' content='#1f2937'></meta>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            ></meta>
            <meta
              name='apple-mobile-web-app-status-bar-style'
              content='#1f2937'
            ></meta>
            <link rel='icon' href='/static/favicon.ico' />
            <link rel='apple-touch-icon' href='/static/favicon.ico'></link>
            <link rel='canonical' href='https://xjackpots40.com/'></link>
          </Head>

          {/* BODY */}
          <div className='bg-background'>
            <CustomGrid cells={bodyCells} />
          </div>
        </Provider>
      </SocketContext.Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

const wrapper = createWrapper(() => store);

export default wrapper.withRedux(App);
