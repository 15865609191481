import Link from 'next/link';
import { HiChevronDown } from 'react-icons/hi';
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const NavItem = ({
  path,
  icon,
  isNavbarOpen,
  iconColor = null,
  title,
  currentPath,
  submenuOpenByDefault,
  submenuItems = [],
  tag,
  tagColor = null,
}) => {
  const isActive = currentPath === path;
  const hasSubmenu = submenuItems.length > 0;
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(submenuOpenByDefault);

  const handleToggleSubmenu = (e) => {
    e.stopPropagation();
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <div
      className={`flex ${isActive ? '' : ''} group relative ${isNavbarOpen ? 'ml-4' : ''}`}
    >
      <div
        className={`${isActive ? 'bg-bg-new' : ''} flex w-full cursor-default flex-col rounded-md py-2 transition-all duration-300 ease-in-out hover:bg-bg-new`}
      >
        <div
          className='flex items-center justify-between'
          onClick={hasSubmenu && isNavbarOpen ? handleToggleSubmenu : undefined}
        >
          {title !== 'İçerikler' ? (
            <Link href={path} className='relative flex w-full'>
              <div className='flex w-full cursor-pointer items-center gap-4 text-base font-light tracking-wide'>
                <div
                  className={`${isActive ? (!iconColor ? 'text-main' : iconColor) : !iconColor ? 'text-text' : iconColor} px-3`}
                >
                  {icon}
                </div>
                {!hasSubmenu && isNavbarOpen ? (
                  <div
                    className={`text-base ${isActive ? (!iconColor ? 'text-main' : iconColor) : !iconColor ? 'text-text/70' : iconColor} ${isActive ? 'font-bold' : 'font-semibold'}`}
                  >
                    {title}
                    {tag && (
                      <div className='absolute -top-[1px] right-2'>
                        <span
                          className={`rounded-sm ${tagColor || 'bg-yellow-500'} px-2 text-xs font-semibold text-black`}
                        >
                          {tag}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-base ${isActive ? (!iconColor ? 'text-main' : iconColor) : !iconColor ? 'text-text/70' : iconColor} ${isActive ? 'font-bold' : 'font-semibold'}`}
                  >
                    {isNavbarOpen ? title : null}
                  </div>
                )}
              </div>
            </Link>
          ) : (
            <div className='relative flex w-full cursor-default'>
              <div className='flex w-full items-center gap-4 text-base font-light tracking-wide'>
                <div
                  className={`${isActive ? (!iconColor ? 'text-main' : iconColor) : !iconColor ? 'text-text' : iconColor} px-3`}
                >
                  {icon}
                </div>
                {!hasSubmenu && isNavbarOpen ? (
                  <div
                    className={`text-base ${isActive ? (!iconColor ? 'text-main' : iconColor) : !iconColor ? 'text-text/70' : iconColor} ${isActive ? 'font-bold' : 'font-semibold'}`}
                  >
                    {title}
                    {tag && (
                      <div className='absolute -top-[1px] right-2'>
                        <span
                          className={`rounded-sm ${tagColor || 'bg-yellow-700'} px-2 text-xs font-semibold text-white`}
                        >
                          {tag}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-base ${isActive ? (!iconColor ? 'text-main' : iconColor) : !iconColor ? 'text-text/70' : iconColor} ${isActive ? 'font-bold' : 'font-semibold'}`}
                  >
                    {isNavbarOpen ? title : null}
                  </div>
                )}
              </div>
            </div>
          )}
          {hasSubmenu && isNavbarOpen && (
            <div className='-translate-x-2 cursor-pointer rounded-sm bg-bgSoft'>
              <HiChevronDown
                className={`p-[3px] text-2xl text-textGrey transition-transform duration-300 ease-in-out ${isSubmenuOpen ? 'rotate-180' : ''}`}
              />
            </div>
          )}
        </div>
        <AnimatePresence>
          {hasSubmenu && (isSubmenuOpen || !isNavbarOpen) && (
            <motion.div
              key={path}
              className='overflow-hidden'
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                height: { duration: 0.3, ease: 'easeInOut' },
                opacity: { duration: 0.3, ease: 'easeInOut' },
              }}
            >
              <div
                className={`flex flex-col ${isNavbarOpen ? 'gap-2 py-2' : 'gap-3'} text-text`}
              >
                {submenuItems.map((item) => {
                  const isSubmenuItemActive = currentPath.startsWith(item.path);
                  return (
                    <Link
                      key={item.path}
                      href={item.path}
                      className={`flex items-center ${isSubmenuItemActive ? 'bg-bg-new' : ''} cursor-pointer rounded-md ${isNavbarOpen ? 'px-3' : 'px-3'} gap-3 py-2 transition-all duration-200 ease-in-out hover:text-yellow-300`}
                    >
                      <div className=''>{item.icon}</div>
                      <div className='flex-grow overflow-hidden whitespace-nowrap text-text'>
                        {isNavbarOpen ? item.title : null}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default NavItem;
