import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { FaCoins } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { pageLinks } from '/components/ProfileComponents/ProfileNav';
import ProfileImage from './LoggedIn/ProfileImage';
import CoinDisplay from './LoggedIn/CoinDisplay';
import { MdOutlineLogout } from 'react-icons/md';
import Logo from '../Logo';
import axios from 'axios';
import { toast } from 'react-toastify';
import useIsMobile from '/components/testComponents/useIsMobile';

const LoggedIn = () => {
  const router = useRouter();
  const route = router.pathname;
  const username = useSelector((state) => state.user.username);
  const currentPoints = useSelector((state) => state.user.pointsCurrent);
  const sipiniyPoints = useSelector((state) => state.user.pointsSipiniy);
  const [isHovered, setIsHovered] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const isMobile = useIsMobile();

  const submenuVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const logout = async () => {
    try {
      await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/auth/logout`, {
        withCredentials: true,
      });
      router.push('/');
      window.location.reload();
    } catch (error) {
      toast.error('Çıkış yapılırken bir hata oluştu.');
    }
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogout = () => {
    setShowLogoutConfirmation(false);
    logout();
  };

  const cancelLogout = () => {
    setShowLogoutConfirmation(false);
  };

  return (
    <>
      {(isMobile && (
        <div className='flex w-full items-center justify-between px-0 md:px-2'>
          <Logo />
          <CoinDisplay jackCoin={currentPoints} sipiniyCoin={sipiniyPoints} />
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className='relative'
          >
            <ProfileImage
              src='https://d38ezbe56qv4qs.cloudfront.net/img/test/profile/avatar-erkek.jpg'
              alt='profile-avatar'
              isMobile={isMobile}
            />
            <AnimatePresence>
              {/* topnav hover menu */}
              {isHovered && (
                <motion.div
                  key='submenu'
                  initial='hidden'
                  animate='visible'
                  exit='hidden'
                  variants={submenuVariants}
                  transition={{ duration: 0.3 }}
                  className='backgradientDark absolute left-1/2 top-[100%] z-50 mt-1 w-[200px] translate-x-[-85%] rounded-lg p-4 text-white ring-1 ring-inset ring-[#395679]'
                >
                  <div className='flex flex-col'>
                    <p>Merhaba, {`${username}`}</p>
                    {pageLinks.map((link) => (
                      <Link
                        onClick={() => setIsHovered(false)}
                        href={link.path}
                        key={link.name}
                        className={`flex items-center gap-2 rounded-lg p-2 font-medium transition hover:text-white ${
                          route === link.path
                            ? 'bg-bgDarkest text-white'
                            : 'text-steelBlue'
                        }`}
                      >
                        {link.icon} {link.name}
                      </Link>
                    ))}
                    <button
                      onClick={handleLogoutClick}
                      className='hover:text-primary text-accentPink flex items-center gap-2 whitespace-nowrap font-medium transition-all duration-500 ease-in-out hover:text-red-600 hover:opacity-100'
                    >
                      <MdOutlineLogout /> Çıkış Yap
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )) || (
        <div className='flex items-center justify-center gap-4'>
          <CoinDisplay jackCoin={currentPoints} />
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className='relative'
          >
            <ProfileImage
              src='https://d38ezbe56qv4qs.cloudfront.net/img/test/profile/avatar-erkek.jpg'
              alt='profile-avatar'
            />
            <AnimatePresence>
              {isHovered && (
                <motion.div
                  key='submenu'
                  initial='hidden'
                  animate='visible'
                  exit='hidden'
                  variants={submenuVariants}
                  transition={{ duration: 0.3 }}
                  className='backgradientDark absolute left-1/2 top-[100%] z-50 mt-1 w-[200px] translate-x-[-85%] rounded-lg p-4 text-white ring-1 ring-inset ring-[#395679]'
                >
                  <div className='flex flex-col'>
                    <p>Merhaba, {`${username}`}</p>
                    {pageLinks.map((link) => (
                      <Link
                        onClick={() => setIsHovered(false)}
                        href={link.path}
                        key={link.name}
                        className={`flex items-center gap-2 rounded-lg p-2 font-medium transition hover:text-white ${
                          route === link.path
                            ? 'bg-bgDarkest text-white'
                            : 'text-steelBlue'
                        }`}
                      >
                        {link.icon} {link.name}
                      </Link>
                    ))}
                    <button
                      onClick={handleLogoutClick}
                      className='hover:text-primary text-accentPink flex items-center gap-2 whitespace-nowrap font-medium transition-all duration-500 ease-in-out hover:text-red-600 hover:opacity-100'
                    >
                      <MdOutlineLogout /> Çıkış Yap
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}

      {showLogoutConfirmation && (
        <div
          className='fixed inset-0 flex items-center justify-center bg-gradient-to-b from-slate-950/40 via-slate-950/20 to-slate-950/0'
          style={{ height: '100dvh', zIndex: 999999 }}
        >
          <div className='border-steelBlue shadow-accentBlue/20 flex flex-col items-center justify-center rounded-lg border bg-[#070f1a] p-8 shadow-lg'>
            <h2 className='mb-4 text-lg font-semibold text-white'>
              Çıkış Yapmak İstediğinize Emin Misiniz?
            </h2>
            <div className='flex justify-end gap-4'>
              <button
                onClick={confirmLogout}
                className='hover:shadow-accentPink/20 rounded-lg bg-pink-600 px-4 py-2 text-white transition duration-300 hover:shadow-lg'
              >
                Evet, Çıkış Yap
              </button>
              <button
                onClick={cancelLogout}
                className='hover:shadow-accentBlue/20 rounded-lg bg-blue-600 px-4 py-2 font-semibold text-white transition duration-300 hover:shadow-lg'
              >
                Hayır
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoggedIn;
