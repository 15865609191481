import { createSlice } from "@reduxjs/toolkit";

const isWideSlice = createSlice({
  name: "isWide",
  initialState:
    typeof window !== "undefined" ? window.innerWidth > 1240 : false,
  reducers: {
    setIsWide: (state, action) => action.payload,
  },
});

export const { setIsWide } = isWideSlice.actions;

export default isWideSlice.reducer;
