import { useRef, useEffect, useState, useCallback } from 'react'

const useChatScroll = (messages) => {
  const chatRef = useRef(null)
  const [isUserNearBottom, setIsUserNearBottom] = useState(true)
  const [newMessages, setNewMessages] = useState(0)
  const previousMessagesCount = useRef(messages.length)

  const scrollToBottom = useCallback(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
    }
    setNewMessages(0)
    setIsUserNearBottom(true)
  }, [])

  useEffect(() => {
    if (messages.length > previousMessagesCount.current) {
      if (isUserNearBottom) {
        scrollToBottom()
      } else {
        setNewMessages((prev) => prev + 1)
      }
      previousMessagesCount.current = messages.length
    }
  }, [messages, isUserNearBottom, scrollToBottom])

  const handleScroll = () => {
    if (chatRef.current) {
      const distanceFromBottom =
        chatRef.current.scrollHeight -
        chatRef.current.scrollTop -
        chatRef.current.clientHeight
      const isNearBottom = distanceFromBottom <= 50 // Set the threshold to 200px

      setIsUserNearBottom(isNearBottom)

      if (isNearBottom) {
        setNewMessages(0)
      }
    }
  }

  return { chatRef, handleScroll, scrollToBottom, newMessages }
}

export default useChatScroll
