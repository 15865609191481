import Image from 'next/image';
import Link from 'next/link';
import { set } from 'nprogress';
import { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { RiKickLine, RiCloseCircleLine } from 'react-icons/ri';
import { TbConfetti } from 'react-icons/tb';
import { FaExternalLinkAlt } from 'react-icons/fa';

const Banner = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [hideForAWhile, setHideForAWhile] = useState(false);
  const imageName = 'second-year';
  const imageExtension = 'gif';

  useEffect(() => {
    const hideUntil = localStorage.getItem('hideUntil-' + imageName);
    if (hideUntil && new Date(hideUntil) > new Date()) {
      setIsOpen(false);
    }
  }, []);

  const closeBanner = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (hideForAWhile) {
      const hideUntil = new Date();
      hideUntil.setHours(hideUntil.getHours() + 12);
      localStorage.setItem('hideUntil-' + imageName, hideUntil.toString());
    }
    setTimeout(() => setIsOpen(false), 300); // 300 milliseconds = 0.3 seconds
  };

  const handleCheckboxChange = (event) => {
    event.stopPropagation();
    setHideForAWhile(!hideForAWhile);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className='btn-standard-green mt-6 flex w-full items-center justify-center bg-red-500 font-bold uppercase'>
          <Link
            // href='https://www.youtube.com/@xjackpots.1'
            href='https://www.youtube.com/watch?v=1EHyHWlmOWU'
            target='_blank'
            rel='noopener noreferrer'
            className='flex items-center gap-2'
            // onclick stop propagation and close banner
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            Yayına Git
            <FaExternalLinkAlt className='h-4 w-4 -translate-y-1/2' />
          </Link>
        </div>
      );
    } else {
      const totalHours = (days * 24 + hours).toString().padStart(2, '0');
      const paddedMinutes = minutes.toString().padStart(2, '0');
      const paddedSeconds = seconds.toString().padStart(2, '0');
      return (
        <div className='mt-6 flex justify-center gap-2 px-2'>
          <div className='flex w-1/3 flex-col items-center gap-3 rounded-md bg-navTransparent/80 py-2 text-2xl font-semibold backdrop-blur-lg md:w-32'>
            {totalHours}
            <span className='text-xs font-light uppercase'>Saat</span>
          </div>

          <div className='flex w-1/3 flex-col items-center gap-3 rounded-md bg-navTransparent/80 py-2 text-2xl font-semibold backdrop-blur-lg md:w-32'>
            {paddedMinutes}
            <span className='text-xs font-light uppercase'>dakika</span>
          </div>

          <div className='flex w-1/3 flex-col items-center gap-3 rounded-md bg-navTransparent/80 py-2 text-2xl font-semibold backdrop-blur-lg md:w-32'>
            {paddedSeconds}
            <span className='text-xs font-light uppercase'>saniye</span>
          </div>
        </div>
      );
    }
  };

  // Set the countdown date to 25.08.2024 18:00 Istanbul time
  const countdownDate = new Date('2024-10-12T19:00:00+03:00');

  return (
    isOpen && (
      <div
        className='fixed top-0 z-[100000] flex h-full w-full flex-col items-center justify-center bg-black/80 backdrop-blur-sm'
        onClick={closeBanner}
        onTouchEnd={closeBanner}
      >
        <div className='relative z-50 h-[90dvh] select-none rounded'>
          <RiCloseCircleLine
            className='absolute right-1 top-1 z-10 h-6 w-6 cursor-pointer text-textGrey transition-colors duration-300 ease-in-out hover:text-text'
            onClick={closeBanner}
            onTouchEnd={closeBanner}
          />
          <div className='relative bg-navTransparent/50 px-6 py-6'>
            <Link
              href='https://www.youtube.com/watch?v=1EHyHWlmOWU'
              rel='noopener noreferrer'
              target='_blank'
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                src={`https://d38ezbe56qv4qs.cloudfront.net/img/test/advertisements/${imageName}.${imageExtension}`}
                alt='Geri Donus Event'
                width={500}
                height={300}
                draggable={false}
                onContextMenu={(e) => e.preventDefault()}
                className='rounded-lg'
              />
            </Link>
            <div className='text-white'>
              <Countdown date={countdownDate} renderer={renderer} />
            </div>
            <div className='mt-4 flex items-center gap-2 border-t border-t-gray-400/30 pt-3'>
              <input
                type='checkbox'
                id='hideCheckbox'
                onClick={handleCheckboxChange}
                className='h-4 w-4 cursor-pointer border border-gray-400/50 bg-black transition-all duration-300 ease-in-out'
              />

              <label
                htmlFor='hideCheckbox'
                className='cursor-pointer text-textGrey'
              >
                Bir süre gösterme
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Banner;
