import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isLoginModalOpen: false,
    isRegister: false,
  },
  reducers: {
    openLoginModal: (state, action) => {
      state.isLoginModalOpen = true;
      state.isRegister = action.payload;
    },
    closeLoginModal: (state) => {
      state.isLoginModalOpen = false;
    },
  },
});

export const { openLoginModal, closeLoginModal } = modalSlice.actions;

export default modalSlice.reducer;
