import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openLoginModal } from '/helpers/modalSlice';

const NonLoggedIn = () => {
  const dispatch = useDispatch();
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768);
  //   };
  //   handleResize(); // Initialize state
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const handleLoginClick = (isRegister) => {
    dispatch(openLoginModal(isRegister));
  };

  return (
    <div className='flex gap-1'>
      <div
        className='buttonGradientBlue'
        onClick={() => handleLoginClick(false)}
      >
        Giriş Yap
      </div>
      <div
        className='flex w-24 cursor-pointer items-center justify-center rounded-lg bg-gradient-to-b from-[#fa6000] to-[#fa5c00] font-bold text-white transition duration-300 hover:-translate-y-[0.05rem] hover:shadow-lg hover:shadow-accentPink/20'
        onClick={() => handleLoginClick(true)}
      >
        Kayıt Ol
      </div>
    </div>
  );
};

export default NonLoggedIn;
