import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false, // Default to false until we know the actual state on the client
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleChat: (state) => {
      state.isOpen = !state.isOpen;
      if (typeof window !== 'undefined') {
        localStorage.setItem('isChatOpen', JSON.stringify(state.isOpen));
      }
    },
    setChatOpen: (state, action) => {
      state.isOpen = action.payload;
      if (typeof window !== 'undefined') {
        localStorage.setItem('isChatOpen', JSON.stringify(action.payload));
      }
    },
    hydrateChatState: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { toggleChat, setChatOpen, hydrateChatState } = chatSlice.actions;

export default chatSlice.reducer;
