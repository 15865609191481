// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: null,
    userUID: null,
    userID: null,
    isAdmin: false,
    badges: [],
    profileImg: null,
    level: 1,
    xp: 0,
    pointsCurrent: 0,
    xpNeeded: 100,
    email: null,
    chatColor: '#ffffff',
    chatBadges: [],
    sponsorIDs: [],
    connections: [],
    trxWallet: null,
    gamdomTrxWallet: null,
    hasMigratedKick: false,
    hasMigratedDosa: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      const {
        username,
        userID,
        isBanned,
        userUID,
        isAdmin,
        email,
        chatBadges,
        chatColor,
        chatSettings,
        levelSystem,
        vipSystem,
        points,
        profileImg,
        sponsorIDs,
        connections,
        trxWallet,
        gamdomTrxWallet,
        hasMigratedKick,
        hasMigratedDosa,
      } = action.payload;
      state.username = username;
      state.userID = userID;
      state.userUID = userUID;
      state.isBanned = isBanned;
      state.isAdmin = isAdmin;
      state.profileImg = profileImg;
      state.chatBadges = chatBadges;
      state.isVip = vipSystem.isVip;
      state.vipLevel = vipSystem.level;
      state.pointsCurrent = points.current;
      state.pointsSipiniy = points.sipiniyPoints;
      state.chatColor = chatColor;
      state.chatSettings = chatSettings;
      state.level = levelSystem.level;
      state.xp = levelSystem.xp;
      state.xpNeeded = levelSystem.xpNeeded;
      state.email = email;
      state.sponsorIDs = sponsorIDs;
      state.connections = connections;
      state.trxWallet = trxWallet;
      state.gamdomTrxWallet = gamdomTrxWallet;
      state.hasMigratedKick = hasMigratedKick;
      state.hasMigratedDosa = hasMigratedDosa;
    },

    setUserWallets: (state, action) => {
      const { trxWallet, gamdomTrxWallet } = action.payload;
      if (trxWallet) {
        state.trxWallet = trxWallet;
      }
      if (gamdomTrxWallet) {
        state.gamdomTrxWallet = gamdomTrxWallet;
      }
    },
    addPoints: (state, action) => {
      state.pointsCurrent += action.payload;
    },
    setPoints: (state, action) => {
      state.pointsCurrent = action.payload;
    },
    spendPoints: (state, action) => {
      state.pointsCurrent -= action.payload;
    },
    addSipiniyPoints: (state, action) => {
      state.pointsSipiniy += action.payload;
    },
    setSipiniyPoints: (state, action) => {
      state.pointsSipiniy = action.payload;
    },
    spendSipiniyPoints: (state, action) => {
      state.pointsSipiniy -= action.payload;
    },
    updateLevelSystem: (state, action) => {
      const { level, xp, xpNeeded } = action.payload;
      state.level = level;
      state.xp = xp;
      state.xpNeeded = xpNeeded;
    },
    updateConnections: (state, action) => {
      state.connections = action.payload;
    },
  },
});

export const {
  setUserInfo,
  addPoints,
  setPoints,
  spendPoints,
  addSipiniyPoints,
  setSipiniyPoints,
  spendSipiniyPoints,
  updateLevelSystem,
  updateConnections,
  setUserWallets,
} = userSlice.actions;
export default userSlice.reducer;
