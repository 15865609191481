import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import xJackLogo from '../../public/xJackLogo.png';
import vipCrown from '../../public/vip-crown.webp';
import trophy from '../../public/trophy.webp';
import NavItem from './NavbarComponents/NavItem';
import Link from 'next/link';
import { AnimatePresence, motion } from 'framer-motion';

// ICONS FOR NAVIGATION
import {
  FaChevronRight,
  FaBars,
  FaTimes,
  FaComments,
  FaHome,
} from 'react-icons/fa';
import { TbHeartFilled } from 'react-icons/tb';
import { IoTicket } from 'react-icons/io5';
import { HiBolt, HiChevronRight } from 'react-icons/hi2';
import { FaTrophy } from 'react-icons/fa';
import { GiBullseye } from 'react-icons/gi';
import { RiGameFill, RiSwordFill } from 'react-icons/ri';
import { TbTournament } from 'react-icons/tb';
import { FaSquarePollHorizontal } from 'react-icons/fa6';
import { MdLocalGroceryStore } from 'react-icons/md';
import { GrStatusPlaceholderSmall } from 'react-icons/gr';
import { FiPocket } from 'react-icons/fi';
import Chatroom from '/components/_appComponents/Chatroom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleChat } from '/helpers/chatSlice';
import { setChatOpen } from '../../helpers/chatSlice';

const PocketIcon = ({ isNavbarOpen, handleToggleNavbar }) => {
  return (
    <motion.div
      animate={{
        rotate: isNavbarOpen ? 180 : 0,
        x: isNavbarOpen ? '15.7rem' : '4.3rem',
      }}
      transition={{ type: 'spring', stiffness: 300, damping: 25 }}
      className={`fixed top-[10dvh] z-[200] translate-y-8 bg-nav p-[0.5rem] text-textGrey hover:cursor-pointer hover:text-white ${
        isNavbarOpen ? 'rounded-lg' : 'rounded-lg'
      }`}
      onClick={handleToggleNavbar}
    >
      <FaChevronRight size={16} />
    </motion.div>
  );
};

const Navbar = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [currentPath, setCurrentPath] = useState(router.pathname);
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [setIsMobileChatOpen, isMobileChatOpen] = useState(false);
  const isChatOpen = useSelector((state) => state.chat.isOpen);

  useEffect(() => setCurrentPath(router.pathname), [router.pathname]);
  const path = router.pathname;

  useEffect(() => {
    setIsNavbarOpen(window.innerWidth >= 1536);
  }, []);

  const mobileLogo =
    'https://d38ezbe56qv4qs.cloudfront.net/img/test/icons/xc-mobile-logo.png';

  const handleToggleNavbar = (e) => {
    e.stopPropagation();
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isChatOpen) dispatch(setChatOpen(false));
  };

  const handleChatToggle = () => {
    dispatch(toggleChat());
    setIsMobileMenuOpen(false);

    // scrolling is disabled when the chat is open and screen width is less or equal to 768px
    if (window.innerWidth <= 768) {
      if (!isChatOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  };

  // #region MOBILE CHAT AND MENU TOGGLES LOGIC
  // ensuring that the chat is closed on mobile screens upon initial mount

  // preventing scroll when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
    return () => (document.body.style.overflow = '');
  }, [isMobileMenuOpen]);

  // closing mobile menu when route changes
  useEffect(() => {
    const handleRouteChange = () => setIsMobileMenuOpen(false);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, setIsMobileMenuOpen]);
  //#endregion

  return (
    <div className='relative shrink-0'>
      {/* Fullscreen Navbar for Mobile */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-100%' }}
            transition={{ type: 'spring', stiffness: 80, damping: 20 }}
            className='fixed inset-0 z-50 overflow-y-scroll bg-bg-new pb-20 pt-12 md:hidden'
          >
            <div className='flex items-center justify-between p-4'>
              <Link href={'/'}>
                <Image
                  src={xJackLogo}
                  alt='xJackpots logo'
                  width={120}
                  height={120}
                />
              </Link>
              <FaTimes
                size={24}
                className='text-white hover:cursor-pointer'
                onClick={() => setIsMobileMenuOpen(false)}
              />
            </div>

            {/* ticket tournament */}
            {/* <Link
              className='backgradientSoft flex h-auto w-full grow-0 flex-col items-center py-2'
              href={'/turnuvalar/gamdom/aylik-cevrim-turnuvasi'}
            >
              <div className='flex h-max w-full flex-col items-center justify-center py-4 text-text'>
                <h2 className='rounded-lg bg-bgTag px-4 py-2 text-lg'>
                  1.000.000 ₺ Ödül
                </h2>
              </div>
              <div className='absolute left-0 top-32 h-28 w-28'>
                <Image
                  fill
                  alt='xJackpots logo'
                  src={trophy}
                  className='object-contain'
                />
              </div>
            </Link> */}

            <div className='mt-4 flex flex-col gap-4 px-4'>
              <NavItem
                path='/sponsorlar'
                isNavbarOpen={true}
                title='Sponsorlar'
                icon={<TbHeartFilled className='text-2xl' />}
                currentPath={currentPath}
              />
              <NavItem
                path='/cekilisler'
                isNavbarOpen={true}
                title='Çekilişler'
                tag={'Yeni'}
                icon={<IoTicket className='text-2xl' />}
                currentPath={currentPath}
              />
              <NavItem
                path=''
                isNavbarOpen={true}
                title='İçerikler'
                icon={<HiBolt className='text-2xl' />}
                iconColor='text-yellow-500'
                currentPath={currentPath}
                submenuOpenByDefault={false}
                submenuItems={[
                  {
                    icon: <GiBullseye className='text-2xl' />,
                    title: 'Bonus Hunt',
                    path: '/icerikler/bonushunt',
                  },
                  {
                    icon: <RiSwordFill className='text-2xl' />,
                    title: 'Bonus Duello',
                    path: '/icerikler/bonusduello',
                  },
                  // {
                  //   icon: <TbTournament className='text-2xl' />,
                  //   title: 'Bonus Turnuva',
                  //   path: '/icerikler/bonusturnuva',
                  // },
                  {
                    icon: <FaSquarePollHorizontal className='text-2xl' />,
                    title: 'Tahmin Yap',
                    path: '/contests',
                  },
                  {
                    icon: <RiGameFill className='text-2xl' />,
                    title: 'Oyun Seçici',
                    path: '/sans/oyun-secici',
                  },
                ]}
              />
              <NavItem
                path='/store'
                isNavbarOpen={true}
                title='Store'
                icon={<MdLocalGroceryStore className='text-2xl' />}
                currentPath={currentPath}
              />

              {/* tournaments */}
              {/* <h3 className='ml-4 mt-4 text-xs font-bold uppercase tracking-widest text-shade'>
                Turnuvalar
              </h3>
              <div className='flex flex-col gap-2'>
                <Link
                  href={'/turnuvalar/gamdom/aylik-cevrim-turnuvasi'}
                  className={`flex items-center gap-4 rounded-md bg-[#102F53] p-2 hover:bg-[#FF4093]`}
                >
                  <FaTrophy className='h-9 w-9 rounded-md bg-accent-gradient-2 p-[10px] text-2xl text-white' />
                  <p className='text-wrap w-3/5 overflow-hidden text-sm font-semibold text-white'>
                    Gamdom Bilet Turnuvası
                  </p>
                </Link>
                <Link
                  href={'/turnuvalar/wanted-001'}
                  className={`flex items-center gap-4 rounded-md bg-[#102F53] p-2 hover:bg-[#FF4093]`}
                >
                  <FaTrophy className='h-9 w-9 rounded-md bg-accent-gradient-2 p-[10px] text-2xl text-white' />
                  <p className='text-wrap w-3/5 overflow-hidden text-sm font-semibold text-white'>
                    Wanted Çarpan Turnuvası
                  </p>
                </Link>
              </div> */}
              {/* vip */}
              {/* <Link
                href={'/vip'}
                className='mx-auto mt-4 flex h-12 w-full shrink-0 items-center justify-between overflow-hidden rounded-md bg-special-gradient p-1 transition-transform duration-300 ease-in-out hover:scale-[1.01]'
              >
                <div className='flex items-center gap-4'>
                  <Image alt='vip logo' src={vipCrown} className='h-10 w-10' />
                  <p
                    className={`${isNavbarOpen ? 'block' : 'hidden'}font-bold text-white`}
                  >
                    VIP
                  </p>
                </div>
                <HiChevronRight
                  className={`mr-2 rounded-md bg-[#BEDCFF2B] p-1 text-2xl text-text ${
                    isNavbarOpen ? 'block' : 'hidden'
                  }`}
                />
              </Link> */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* nav toggle button */}
      <div className='hidden md:block'>
        <PocketIcon
          isNavbarOpen={isNavbarOpen}
          handleToggleNavbar={handleToggleNavbar}
        />
      </div>

      {/* navbar */}
      <div
        className={`z-50 hidden md:flex md:flex-col ${
          isNavbarOpen
            ? 'fixed left-0 top-14 z-50 min-w-[260px] 2xl:sticky 2xl:top-2'
            : 'sticky top-14 z-0 w-[5rem] 2xl:top-2'
        } ml-1 h-[calc(98dvh-3.5rem)] shrink-0 justify-between overflow-auto overflow-x-hidden rounded-lg bg-nav p-4 transition-heightAndWidth duration-300 ease-in-out 2xl:h-[98vh]`}
      >
        <div className='mt-2 flex flex-col gap-2'>
          <Link href='/' className=''>
            {isNavbarOpen ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ opacity: { duration: 0.4 } }}
              >
                <Image alt='xJackpots logo' src={xJackLogo} className='' />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ opacity: { duration: 0.4 } }}
              >
                <Image
                  alt='xJackpots logo'
                  src={mobileLogo}
                  width={32}
                  height={32}
                  className='mx-auto -mb-1 -mt-1'
                />
              </motion.div>
            )}
          </Link>
          {/* NAVIGATION */}
          <div className='mt-4 flex flex-col gap-2'>
            <NavItem
              path='/sponsorlar'
              isNavbarOpen={isNavbarOpen}
              title='Sponsorlar'
              icon={<TbHeartFilled className='text-2xl' />}
              currentPath={currentPath}
            />
            <NavItem
              path='/cekilisler'
              isNavbarOpen={isNavbarOpen}
              title='Çekilişler'
              tag={'Yeni'}
              icon={<IoTicket className='text-2xl' />}
              currentPath={currentPath}
            />
            <NavItem
              path=''
              isNavbarOpen={isNavbarOpen}
              title='İçerikler'
              icon={
                <HiBolt className={`${isNavbarOpen ? 'text-2xl' : 'hidden'}`} />
              }
              iconColor='text-yellow-500'
              currentPath={currentPath}
              submenuOpenByDefault={false}
              submenuItems={[
                {
                  icon: <GiBullseye className='text-2xl' />,
                  title: 'Bonus Hunt',
                  path: '/icerikler/bonushunt',
                },
                {
                  icon: <RiSwordFill className='text-2xl' />,
                  title: 'Bonus Duello',
                  path: '/icerikler/bonusduello',
                },
                // {
                //   icon: <TbTournament className='text-2xl' />,
                //   title: 'Bonus Turnuva',
                //   path: '/icerikler/bonusturnuva',
                // },
                {
                  icon: <FaSquarePollHorizontal className='text-2xl' />,
                  title: 'Tahmin Yap',
                  path: '/contests',
                },
                {
                  icon: <RiGameFill className='text-2xl' />,
                  title: 'Oyun Seçici',
                  path: '/sans/oyun-secici',
                },
              ]}
            />
            <NavItem
              path='/store'
              isNavbarOpen={isNavbarOpen}
              title='Store'
              icon={<MdLocalGroceryStore className='text-2xl' />}
              currentPath={currentPath}
            />
          </div>
          {/* <h3
            className={`${
              isNavbarOpen ? 'block' : 'hidden'
            } ml-4 mt-4 text-xs font-bold uppercase tracking-widest text-shade`}
          >
            Turnuvalar
          </h3> */}
          {/* TOURNAMENTS */}
          {/* <div className='flex flex-col gap-2'>
            <Link
              href={'/turnuvalar/gamdom/aylik-cevrim-turnuvasi'}
              className={`flex items-center gap-4 rounded-md ${
                isNavbarOpen
                  ? 'bg-[#102F53] p-2 hover:bg-[#FF4093]'
                  : 'self-center'
              } transition-all duration-300 ease-in-out`}
            >
              <FaTrophy
                className={`${
                  isNavbarOpen ? 'h-9 w-9' : 'h-9 w-12'
                } rounded-md bg-accent-gradient-2 p-[10px] text-2xl text-white`}
              />
              <p
                className={`${
                  isNavbarOpen ? 'block' : 'hidden'
                } text-wrap w-3/5 overflow-hidden text-sm font-semibold text-white`}
              >
                Gamdom Bilet Turnuvası
              </p>
            </Link>
            <Link
              href={'/turnuvalar/wanted-001'}
              className={`flex items-center gap-4 rounded-md ${
                isNavbarOpen
                  ? 'bg-[#102F53] p-2 hover:bg-[#FF4093]'
                  : 'self-center'
              } transition-all duration-300 ease-in-out`}
            >
              <FaTrophy
                className={`${
                  isNavbarOpen ? 'h-9 w-9' : 'h-9 w-12'
                } rounded-md bg-accent-gradient-2 p-[10px] text-2xl text-white`}
              />
              <p
                className={`${
                  isNavbarOpen ? 'block' : 'hidden'
                } text-wrap w-3/5 overflow-hidden text-sm font-semibold text-white`}
              >
                Wanted Çarpan Turnuvası
              </p>
            </Link>
          </div> */}
        </div>
        {/* <Link
          href={'/vip'}
          className='mx-auto mt-4 flex h-12 w-full shrink-0 items-center justify-between overflow-hidden rounded-md bg-special-gradient p-1 transition-transform duration-300 ease-in-out hover:scale-[1.01]'
        >
          <div className='flex items-center gap-4'>
            <Image alt='vip logo' src={vipCrown} className='h-10 w-10' />
            <p
              className={`${isNavbarOpen ? 'block' : 'hidden'}font-bold text-white`}
            >
              VIP
            </p>
          </div>
          <HiChevronRight
            className={`mr-2 rounded-md bg-[#BEDCFF2B] p-1 text-2xl text-text ${
              isNavbarOpen ? 'block' : 'hidden'
            }`}
          />
        </Link> */}
      </div>

      {/* BOTTOM NAVIGATION FOR MOBILE */}
      <div className='fixed -bottom-1 left-0 right-0 z-[200] flex items-center justify-around border-t border-t-[#395679] bg-bg-new p-[0.5rem_0_0.75rem_0] md:hidden'>
        {/* menu */}
        <div
          className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200`}
          onClick={handleMobileMenuToggle}
        >
          <FaBars
            size={24}
            className={`${isMobileMenuOpen ? 'scale-105' : ''} text-[#55FEC2] hover:cursor-pointer`}
          />
        </div>
        {/* sponsors */}
        <Link href='/sponsorlar'>
          <div
            className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200 ${
              path === '/sponsorlar'
                ? 'scale-105 bg-bgDarkest text-white'
                : 'text-steelBlue hover:bg-steelBlue hover:text-white'
            }`}
          >
            <TbHeartFilled className='text-2xl' />
          </div>
        </Link>
        {/* raffles */}
        <Link href='/cekilisler'>
          <div
            className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200 ${
              path === '/cekilisler'
                ? 'scale-105 bg-bgDarkest text-white'
                : 'text-steelBlue hover:bg-steelBlue hover:text-white'
            }`}
          >
            <IoTicket className='text-2xl' />
          </div>
        </Link>
        {/* contents */}
        <Link href='/'>
          <div
            className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200 ${
              path === '/'
                ? 'scale-105 bg-bgDarkest text-white'
                : 'text-steelBlue hover:bg-steelBlue hover:text-white'
            }`}
          >
            <FaHome className='text-2xl' />
          </div>
        </Link>
        {/* store */}
        <Link href='/store'>
          <div
            className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200 ${
              path === '/store'
                ? 'scale-105 bg-bgDarkest text-white'
                : 'text-steelBlue hover:bg-steelBlue hover:text-white'
            }`}
          >
            <MdLocalGroceryStore className='text-2xl' />
          </div>
        </Link>
        {/* vip */}
        {/* <Link className='hidden hobbit:block' href='/vip'>
          <div
            className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200 ${
              path === '/vip'
                ? 'scale-105 bg-bgDarkest text-white'
                : 'text-steelBlue hover:bg-steelBlue hover:text-white'
            }`}
          >
            <FaTrophy className='text-2xl' />
          </div>
        </Link> */}
        {/* chat */}
        <div
          className={`flex items-center rounded-lg border border-transparent px-3 py-2 transition-all duration-200`}
          onClick={handleChatToggle}
        >
          <Chatroom />
          <FaComments
            className={`text-2xl ${isChatOpen ? 'scale-110 text-accentBlue' : 'text-accentPink'}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
