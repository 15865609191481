import Link from 'next/link';
import { useState } from 'react';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosChatbubbles } from 'react-icons/io';
import { logIn, logOut } from '/helpers/authSlice';
import NonLoggedIn from './LoginComponent/NonLoggedIn';
import LoggedIn from './LoginComponent/LoggedIn';
import { toggleChat } from '/helpers/chatSlice';

const AuthButton = () => {
  const dispatch = useDispatch();
  const isChatOpen = useSelector((state) => state.chat.isOpen);
  const handleLogin = () => {
    dispatch(logIn());
  };

  const handleLogout = () => {
    dispatch(logOut());
  };

  const handleChatToggle = () => {
    dispatch(toggleChat());
  };

  const isLoggedIn = useSelector((loginState) => loginState.auth.isLoggedIn);

  return (
    <div
      className={`relative flex w-full items-center gap-2 ${isLoggedIn ? 'md:justify-end' : 'justify-end'}`}
    >
      {isLoggedIn ? <LoggedIn /> : <NonLoggedIn />}
      {!isChatOpen && (
        <div
          onClick={handleChatToggle}
          className='group hidden cursor-pointer items-center gap-2 rounded-lg bg-[#50124a] px-4 py-2 text-text/70 transition-all duration-300 ease-in-out hover:bg-[#FF4093] md:flex'
        >
          <IoIosChatbubbles className='cursor-pointer rounded-lg text-2xl text-accent transition-all duration-300 ease-in-out group-hover:text-black' />
          <p className='hidden text-text transition-colors duration-300 ease-in-out group-hover:text-black md:inline-block'>
            Sohbet
          </p>
        </div>
      )}
    </div>
  );
};

export default AuthButton;
