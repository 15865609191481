import { useState, useEffect } from 'react';

function useIsMobile(maxBreakpoint = 768) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < maxBreakpoint);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < maxBreakpoint);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [maxBreakpoint]);

  return isMobile;
}

export default useIsMobile;
