import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import profile from '../../../public/profile.png';
import { processMessage } from '../../../utils/messageUtils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { IoMdClose } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import { TbHexagonLetterS, TbHexagonLetterN } from 'react-icons/tb';

const MessageBoxComponent = ({ message }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeUsername, setActiveUsername] = useState(null); // Track the currently active username
  const isChatOpen = useSelector((state) => state.chat.isOpen);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [userData]);

  const handleUsernameClick = async (username) => {
    if (activeUsername === username) {
      // If the same username is clicked, close the modal
      setActiveUsername(null);
      setUserData(null);
      return;
    }

    // Set the active username and reset previous states
    setActiveUsername(username);
    setIsLoading(true);
    setError(null);

    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/user/social-data/${username}`,
        { withCredentials: true }
      );
      setUserData(data.data);

      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const translateBadge = (vipLevel, badges) => {
    // Check for badges first, giving them priority
    if (badges.length > 0) {
      if (badges.includes('moderator')) {
        return 'MOD';
      }
      if (badges.includes('vip')) {
        return 'VIP';
      }
      if (badges.includes('cenk')) {
        return 'CENK';
      }
      if (badges.includes('developer')) {
        return 'DEVELOPER';
      }
      if (badges.includes('noodle')) {
        return 'MAKARNA';
      }
    }

    // If no badge matches, fall back to VIP level
    switch (vipLevel) {
      case 0:
        return 'Üye';
      case 1:
        return 'Silver VIP';
      case 2:
        return 'Gold VIP';
      case 3:
        return 'Platinum VIP';
      case 4:
        return 'Diamond VIP';
      default:
        return 'Üye';
    }
  };

  return (
    <AnimatePresence>
      {isChatOpen && (
        <motion.div
          key={uuidv4()}
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 15 }}
          transition={{ duration: 0.2 }}
          className='mb-2 flex items-start gap-2'
        >
          <div className='relative'>
            <Image
              src={
                '	https://d38ezbe56qv4qs.cloudfront.net/img/test/profile/avatar-erkek.jpg'
              }
              width={32}
              height={32}
              className='translate-y-[2px] cursor-pointer rounded-lg border-2 border-accentBlue shadow-sm shadow-accentBlue drop-shadow-md'
              alt='Profile'
              onClick={() => handleUsernameClick(message.username)} // Pass the username
            />
            {isLoading && (
              <div className='absolute -bottom-10 left-0 h-full w-[240px] bg-black/50 backdrop-blur-sm'></div>
            )}
            {error && (
              <div className='absolute -bottom-10 left-0 h-full w-full bg-red-500 text-center text-white'>
                <p className='text-sm'>Hata: {error.message}</p>
              </div>
            )}
            {userData &&
              activeUsername === message.username && ( // Only show if active username matches
                <div
                  className={`absolute -bottom-10 left-0 z-10 ${userData?.chatMessages?.length > 0 ? 'h-64' : 'h-32'} w-[240px] overflow-hidden rounded-md border border-gray-600 bg-black/50 backdrop-blur-sm`}
                  key={userData.username}
                >
                  <div className='mb-3 flex items-center gap-2 px-2 py-1'>
                    <Image
                      src={userData.profileImg}
                      width={32}
                      height={32}
                      className='translate-y-[2px] rounded-lg border-2 border-accentBlue shadow-sm shadow-accentBlue drop-shadow-md'
                      alt='Profile'
                    />
                    <div className='flex w-[240px] justify-between'>
                      <div className='flex flex-col'>
                        <p className='mb-2 translate-y-1 text-xs font-bold text-text'>
                          {userData.username}
                        </p>
                        <p className='text-xs font-bold text-textGrey'>
                          {translateBadge(
                            userData.vipLevel,
                            userData.chatBadges
                          )}
                        </p>
                      </div>
                      <IoMdClose
                        className='text-lg text-textGrey'
                        onClick={() => setActiveUsername(null)} // Close the modal on click
                      />
                    </div>
                  </div>
                  {userData?.chatMessages?.length > 0 ? (
                    <div
                      ref={scrollRef}
                      className='h-[75%] overflow-y-auto bg-navTransparent/70 py-2'
                    >
                      {userData?.chatMessages
                        ?.toReversed()
                        .map((message, index) => (
                          <div
                            className='relative my-2 flex items-center justify-between p-1'
                            key={index}
                          >
                            <p className='break-all text-sm font-semibold text-text'>
                              {processMessage(message.message)}
                            </p>
                            <p className='absolute -top-3 right-0 text-xs font-semibold text-textGrey'>
                              {new Date(message.timestamp).toLocaleString(
                                'tr-TR'
                              )}
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className='grid grid-flow-col grid-cols-2 gap-1 overflow-y-auto bg-navTransparent/70 px-1 py-2'>
                      <div className='flex flex-col items-center justify-center gap-1 text-text'>
                        <TbHexagonLetterN className='text-xl text-main' />
                        {userData?.currentPoints?.toLocaleString('tr-TR')}{' '}
                      </div>
                      <div className='flex flex-col items-center justify-center gap-1 text-text'>
                        <TbHexagonLetterS className='text-xl text-[#a0ff00]' />
                        {userData?.currentSipiniyPoints?.toLocaleString(
                          'tr-TR'
                        )}{' '}
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>

          <div
            className='wrap mr-2 flex-1 overflow-hidden rounded-md p-2'
            style={{ backgroundColor: message.boxColor || '#05173150' }}
          >
            <div className='flex items-center justify-between'>
              <p
                className='mb-2 cursor-pointer text-xs font-bold text-textGrey transition-colors duration-300 ease-in-out hover:text-text'
                onClick={() => handleUsernameClick(message.username)} // Pass the username
              >
                {'[' +
                  translateBadge(
                    message.vipLevel || 0,
                    message.chatBadges || []
                  ) +
                  ']'}{' '}
                &nbsp;
                {message.username}{' '}
              </p>
            </div>
            {message.usersCanSee ? (
              <p
                className='break-words text-sm font-semibold'
                style={{ color: message.textColor || '#F0F0F0' }}
              >
                {processMessage(message.message)}
              </p>
            ) : (
              <p className='break-words text-xs font-semibold text-textGrey/30'>
                Bu mesaj moderatörler tarafından silinmiştir.
              </p>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(MessageBoxComponent);
