//#region IMPORTS
import { logIn } from '/helpers/authSlice';
import axios from 'axios';
import { useRouter } from 'next/router';
import useSound from 'use-sound';
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { closeLoginModal, openLoginModal } from '/helpers/modalSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  AiOutlineArrowLeft,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';

import casino from '../../public/CASINO.webp';
import xJackLogo from '../../public/xJackLogo.png';
import Image from 'next/image';
import Confetti from 'react-confetti';
import { easeInOut, motion } from 'framer-motion';
//#endregion

const LoginModal = () => {
  // FUNCTIONAL HOOKS
  const dispatch = useDispatch();
  const router = useRouter();
  const recaptchaRef = useRef();

  // SHOW PASSWORD STATE
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  // FORGOT PASSWORD RELATED STATES
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [isForgotSubmitLoading, setIsForgotSubmitLoading] = useState(false);
  const [isForgotSubmitDone, setIsForgotSubmitDone] = useState(false);
  // const [play] = useSound(
  //   'https://d38ezbe56qv4qs.cloudfront.net/img/test/root/monkey.mp3',
  //   { volume: 0.1 }
  // );

  // RESET PASSWORD STATES
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [token, setToken] = useState('');
  const [resetPasswordFeedback, setResetPasswordFeedback] = useState('');

  // FORM STATES & RESET
  const isRegister = useSelector((state) => state.modal.isRegister);
  const [isLogin, setIsLogin] = useState(!isRegister);
  const [rememberMe, setRememberMe] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [usernameError, setUsernameError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const resetFormStates = () => {
    setEmail('');
    setIsResetPassword(false);
    setIsForgotPassword(false);
    setIsForgotSubmitDone(false);
    setNewPassword('');
    setConfirmPassword('');
    setFormData({
      username: '',
      email: '',
      password: '',
    });
  };

  // MODAL VISIBILITY CHECK
  const modalIsOpen = useSelector((state) => state.modal.isLoginModalOpen);
  const modalIsClosed = !modalIsOpen;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [captchaValue, setCaptchaValue] = useState(null);

  // USEEFFECT HOOKS
  useEffect(() => {
    setIsLogin(!isRegister);
  }, [isRegister]);

  useEffect(() => {
    if (recaptchaRef.current) recaptchaRef.current.reset();
  }, [isLogin]);

  useEffect(() => {
    if (router.asPath) {
      // Splitting the path into parts to extract the token
      const pathParts = router.asPath.split('/');

      // Extracting token from the parts & checking url
      const tokenFromPath = pathParts.pop();

      if (pathParts.includes('reset-password')) {
        setToken(tokenFromPath);
        setIsResetPassword(true);
        openResetModal();
      }
    }
  }, [router]);

  useEffect(() => {
    modalIsOpen
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [modalIsOpen]);

  // RECAPTCHA HANDLERS
  const handleCaptchaChange = (value) => setCaptchaValue(value);
  const handleCaptchaExpired = () => setCaptchaValue(null);

  // FORGOT PASSWORD HANDLERS
  const handleForgotPassword = () => {
    setIsForgotSubmitDone(false);
    setIsForgotPassword(true);
    setIsLogin(false);
  };

  const handleForgotPasswordBack = () => {
    setIsForgotPassword(false);
    setIsResetPassword(false);
    setIsLogin(true);
    resetFormStates();
  };

  const handleForgotMailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotMailSubmit = async (e) => {
    e.preventDefault();
    setIsForgotSubmitLoading(true);
    // play();
    setShowConfetti(true);
    setTimeout(async () => {
      setShowConfetti(false);
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/auth/request-reset-password`,
          {
            email,
          }
        );
        router.push('/');
      } catch (error) {
        // toast.success('Eğer bu mail adresi sistemimizde kayıtlıysa sıfırlama maili gönderildi.');
        console.log(error);
      } finally {
        setIsForgotSubmitDone(true);
        setIsForgotSubmitLoading(false); // Ensure this is always called
      }
    }, 3000);
  };

  // RESET PASSWORD HANDLERS
  const handleResetPasswordChange = (e) => {
    setIsForgotPassword(false);
    setNewPassword(e.target.value);
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setResetPasswordFeedback('');
    setTimeout(async () => {
      // guard check
      if (!token) {
        const feedback = 'Geçersiz veya eksik doğrulama bilgisi.';
        setResetPasswordFeedback(feedback);
        console.log(feedback);
        return;
      }

      // success
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/auth/reset-password`,
          {
            token,
            newPassword,
          }
        );
        const feedback = response.data.msg;
        setResetPasswordFeedback(feedback);
        router.push('/');

        // error
      } catch (error) {
        const feedback = error.response?.data?.msg || 'Bir hata olustu';
        setResetPasswordFeedback(feedback);
        console.log(error.response?.data?.msg || 'Bir hata olustu');
      }
    }, 1000);
  };

  // FRAMER MOTION ANIMATIONS FOR LOGIN, REGISTER AND FORGOT PASSWORD
  const loginModalAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  // MODAL CLOSER
  const closeModal = () => {
    // in case of a reset password cancelation return to the home page 👇🏻
    if (router.asPath) {
      const pathParts = router.asPath.split('/');
      if (pathParts.includes('reset-password')) router.push('/');
    }

    dispatch(closeLoginModal());
    setIsLogin(!isRegister);
    setIsResetPassword(false);
    setIsForgotPassword(false);
    resetFormStates();
  };

  // MODAL OPENER
  const openResetModal = () => {
    dispatch(openLoginModal());
    setIsForgotPassword(false);
  };

  // USERNAME VALIDATION || TODO: USE ZOD
  const validateUsername = (username) => {
    const regex = /^[a-zA-Z0-9_]*$/;
    if (username.length > 2 && username.length < 4) {
      setUsernameError('Çok kısa. Minimum 4 karakter girmelisiniz.');
      setIsUsernameValid(false);
    } else if (username.length > 25) {
      setUsernameError('Çok uzun. Maksimum 25 karakter girmelisiniz.');
      setIsUsernameValid(false);
    } else if (!username.match(regex)) {
      setUsernameError('Sadece harf, rakam ve alt çizgi kullanabilirsiniz.');
      setIsUsernameValid(false);
    } else {
      setUsernameError('');
      setIsUsernameValid(true);
    }
  };

  // INPUT HANDLERS
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') validateUsername(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // PASSWORDS MATCH CHECK
    if (
      (!isLogin || isResetPassword) &&
      (formData.password || confirmPassword) !== confirmPassword
    ) {
      toast.error('Şifreler eşleşmiyor.');
      setLoading(false);
      return;
    }

    const url = isLogin
      ? `${process.env.NEXT_PUBLIC_API_URL}/auth/login`
      : `${process.env.NEXT_PUBLIC_API_URL}/auth/register`;

    const payload = {
      ...formData,
      rememberMe,
      visitorID: null,
      confidence: null,
      ipAddress: null,
      captcha: captchaValue,
    };

    try {
      const response = await axios.post(url, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = response.data;
      if (response.status === 200 && data.msg) {
        if (isLogin) {
          dispatch(logIn());
          router.push('/profile');
          router.reload();
          toast.success(data.msg);
        } else {
          toast.success('Hesap Başarıyla Oluşturuldu.');
          setIsLogin(true);
        }
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.msg || 'Request failed');
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
      if (recaptchaRef.current) recaptchaRef.current.reset();
    }
  };

  return (
    <>
      {showConfetti && <Confetti />}
      {modalIsOpen && !isLoggedIn && (
        // MODAL
        <div
          className='fixed inset-0 z-[999999] flex items-center justify-center bg-bgDark bg-opacity-40'
          onMouseDown={closeModal}
        >
          {/* POPUP */}
          <div
            className='h-[98dvh] w-full overflow-y-auto overflow-x-hidden rounded-lg bg-bgDark p-2 pb-4 sm:h-auto sm:w-96 md:h-auto md:w-[438px]'
            // stopped propagation to prevent closing modal when clicked inside
            onMouseDown={(e) => e.stopPropagation()}
          >
            {/* MODAL HERO IMAGE */}
            <div className='relative mb-2 h-36 w-full overflow-hidden rounded-lg'>
              <Image src={casino} fill alt='Header' objectFit='cover' />
              <button
                onClick={closeModal}
                className='absolute right-2 top-2 text-xl'
              >
                <IoClose className='h-8 w-8 cursor-pointer rounded-md bg-nav p-1 text-steelBlue' />
              </button>

              {/* FORGOT PASSWORD BACK BUTTON */}
              {isForgotPassword && (
                <button
                  onClick={handleForgotPasswordBack}
                  className='absolute left-2 top-2 text-xl'
                >
                  <AiOutlineArrowLeft
                    style={{
                      backgroundColor: '#1B3250',
                      padding: '4px',
                      borderRadius: '8px',
                    }}
                    size={32}
                    color='#6B8CB4'
                  />
                </button>
              )}
            </div>

            {/* xJACKPOTS LOGO */}
            {!isForgotPassword && (
              <Image alt='xJackpots logo' src={xJackLogo} />
            )}

            {/* RESET PASSWORD FEEDBACK & ANIMATION */}
            {isResetPassword && (
              <motion.p
                key={resetPasswordFeedback}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className='mb-4 ml-4 text-center text-sm text-white md:text-base'
              >
                {resetPasswordFeedback}
              </motion.p>
            )}

            {/* SWITCHER */}
            {!isForgotPassword && !isResetPassword && (
              <div className='relative mb-4 ml-4 flex w-fit flex-row justify-start rounded-lg bg-bgSoft text-steelBlue'>
                <div
                  className={`absolute left-0 top-0 mx-1 h-[90%] w-1/2 translate-y-[3px] rounded-lg bg-nav transition-transform duration-300 ease-in-out ${
                    isLogin ? 'translate-x-0' : 'translate-x-[94%]'
                  }`}
                ></div>
                <button
                  onClick={() => setIsLogin(true)}
                  className={`relative z-10 rounded-lg px-8 py-4 text-sm ${
                    isLogin ? 'text-white' : 'text-steelBlue'
                  }`}
                >
                  Giriş Yap
                </button>
                <button
                  onClick={() => {
                    setIsLogin(false);
                    resetFormStates();
                  }}
                  className={`relative z-10 rounded-lg px-8 py-4 text-sm ${
                    !isLogin ? 'text-white' : 'text-steelBlue'
                  }`}
                >
                  Kayıt Ol
                </button>
              </div>
            )}

            {/* FORM */}
            <motion.form
              onSubmit={
                isForgotPassword
                  ? handleForgotMailSubmit
                  : isResetPassword
                    ? handleResetPasswordSubmit
                    : handleSubmit
              }
              className='flex flex-col gap-4 px-4'
              variants={loginModalAnimation}
              initial='hidden'
              animate='visible'
              key={isLogin ? 'login-form' : 'register-form'}
            >
              {/* USERNAME INPUT */}
              {!isForgotPassword && !isResetPassword && (
                <div className='flex flex-col gap-1'>
                  <div className='relative'>
                    <input
                      name='username'
                      value={formData.username}
                      onChange={handleChange}
                      required
                      type='text'
                      placeholder='Kullanıcı adı'
                      className='w-full rounded-lg bg-nav p-4 text-sm text-white placeholder-steelBlue focus:outline-none focus:ring-2 focus:ring-accentBlue'
                    />
                    {usernameError && (
                      <div className='absolute -bottom-5 text-sm text-red-500'>
                        {usernameError}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* EMAIL INPUT */}
              {((!isLogin && !isResetPassword) || isForgotPassword) && (
                <div className='flex flex-col gap-1'>
                  {isForgotPassword && !isLogin && (
                    <>
                      {/* while the user is entering the email address */}
                      {!isForgotSubmitDone && (
                        <>
                          <h1 className='mb-4 mt-4 text-xl font-bold tracking-wider text-white sm:text-3xl'>
                            Şifrenizi mi unuttunuz?
                          </h1>
                          <p className='mb-2 border-l-2 border-l-steelBlue pl-2 text-sm text-steelBlue'>
                            Email adresinizi girin ve şifrenizi sıfırlamanız
                            için bir bağlantı adresi gönderelim.
                          </p>
                        </>
                      )}
                      {/* after the user has submitted the email address */}
                      {isForgotSubmitDone && (
                        <>
                          <h1 className='mb-4 mt-4 text-xl font-bold tracking-wider text-white sm:text-3xl'>
                            Email adresinizi kontrol ediniz.
                          </h1>
                          <p className='mb-2 border-l-2 border-l-steelBlue pl-2 text-sm text-steelBlue'>
                            Girdiğiniz mail adresi sistemimizde kayıtlı ise
                            <br />
                            şifrenizi sıfırlamanız için size bir mail gönderdik.
                          </p>
                        </>
                      )}
                    </>
                  )}

                  {!isForgotSubmitDone && (
                    <input
                      name='email'
                      value={isForgotPassword ? email : formData.email}
                      onChange={
                        isForgotPassword ? handleForgotMailChange : handleChange
                      }
                      required
                      type='email'
                      placeholder='E-posta adresi'
                      className='w-full rounded-lg bg-nav p-4 text-sm text-white placeholder-steelBlue focus:outline-none focus:ring-2 focus:ring-accentBlue'
                    />
                  )}
                </div>
              )}

              {/* PASSWORD INPUT */}
              {!isForgotPassword && (
                <div className='relative'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={isResetPassword ? newPassword : formData.password}
                    onChange={
                      isResetPassword ? handleResetPasswordChange : handleChange
                    }
                    placeholder={isResetPassword ? 'Yeni şifre' : 'şifre'}
                    className='w-full rounded-lg bg-nav p-4 text-sm text-white placeholder-steelBlue focus:outline-none focus:ring-2 focus:ring-accentBlue'
                  />
                  <span
                    className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer rounded-lg bg-bgSoft p-2 text-steelBlue'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                </div>
              )}

              {!isLogin && !isForgotPassword && !isResetPassword && (
                // CHECK PASSWORD INPUT
                <div className='relative'>
                  <input
                    type={showRepeatPassword ? 'text' : 'password'}
                    name='confirmPassword'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder='Şifre tekrar'
                    className='w-full rounded-lg bg-nav p-4 text-sm text-white placeholder-steelBlue focus:outline-none focus:ring-2 focus:ring-accentBlue'
                  />
                  <span
                    className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer rounded-lg bg-bgSoft p-2 text-steelBlue'
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  >
                    {showRepeatPassword ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                </div>
              )}

              {/* REMEMBER ME CHECKBOX INPUT */}
              {!isResetPassword && (
                <div className='flex items-center justify-between'>
                  {isLogin && !isForgotPassword && (
                    <label className='flex cursor-pointer items-center'>
                      <input
                        type='checkbox'
                        className='sr-only'
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <div className='ml-1 h-4 w-4 flex-shrink-0 rounded-sm border border-gray-700 bg-nav transition-colors duration-200'>
                        {rememberMe && (
                          <FaCheck className='h-3 w-3 translate-x-[1px] text-main' />
                        )}
                      </div>
                      <span className='ml-2 text-sm text-textGrey'>
                        Beni hatırla
                      </span>
                    </label>
                  )}
                </div>
              )}

              {/* reCAPTCHA */}
              {!isForgotSubmitDone && !isResetPassword && (
                <div className='my-2 flex justify-start'>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey='6Lcvr0EpAAAAAPiYVNxMKVCgzvbbG-kctD8OJ01a'
                    onChange={handleCaptchaChange}
                    onExpired={handleCaptchaExpired}
                    theme='dark'
                  />
                </div>
              )}

              {/* BUTTONS */}
              {!isForgotSubmitDone && (
                <button
                  type='submit'
                  disabled={!isUsernameValid || isForgotSubmitLoading}
                  className='sm:text-md w-full touch-manipulation select-none rounded-lg bg-[#ffc400] px-8 py-4 text-sm font-bold text-black focus:outline-none focus:ring-2 focus:ring-white sm:w-auto'
                >
                  {/* To apply the animation only to the text 👇🏻 */}
                  <motion.div
                    animate={{
                      scale: isForgotSubmitLoading ? [1, 1.1, 1] : 1,
                    }}
                    transition={{
                      duration: 0.6,
                      ease: 'easeInOut',
                      repeat: isForgotSubmitLoading ? Infinity : 0,
                    }}
                    // To prevent motion.div from affecting button's laytout 👇🏻
                    style={{ display: 'inline-block' }}
                  >
                    {isResetPassword
                      ? 'Şifreyi Yenile'
                      : isLogin && !isForgotPassword
                        ? 'Giriş Yap'
                        : !isLogin && !isForgotPassword
                          ? 'Kayıt Ol'
                          : isForgotSubmitLoading
                            ? 'Gönderiliyor'
                            : 'Gönder'}
                  </motion.div>
                </button>
              )}

              {/* FORGOT PASSWORD BUTTON */}
              {isLogin && !isForgotPassword && !isResetPassword && (
                <span
                  onClick={handleForgotPassword}
                  className='hover:text-400 inline-block cursor-pointer py-2 text-center text-sm font-bold text-[#1F7FF0]'
                >
                  Şifremi Unuttum
                </span>
              )}
            </motion.form>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
