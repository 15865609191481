import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PiPushPin } from 'react-icons/pi';
import { processMessage } from '../../../utils/messageUtils';
import { useSelector } from 'react-redux';

const PinnedMessage = ({ pinned }) => {
  const [hasPinOn, setHasPinOn] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true); // Track loading state
  const isChatOpen = useSelector((state) => state.chat.isOpen);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const storedIsExpanded = localStorage.getItem('isExpanded');
    if (storedIsExpanded !== null) {
      setIsExpanded(JSON.parse(storedIsExpanded));
    }
    setLoading(false); // Set loading to false after loading state
  }, []);

  useEffect(() => {
    if (!loading) {
      // Only set item in localStorage if it's not loading
      localStorage.setItem('isExpanded', JSON.stringify(isExpanded));
    }
  }, [isExpanded, loading]);

  if (loading) {
    return null; // Render nothing while loading
  }

  return (
    <AnimatePresence>
      {hasPinOn && pinned?.message && isChatOpen && (
        <motion.div
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 15 }}
          transition={{ opacity: { duration: 0.4 } }}
          className={`absolute top-12 z-10 min-w-full max-w-xs cursor-pointer overflow-hidden ${isExpanded ? 'rounded-md' : 'rounded-2xl'} bg-white/10 px-2 py-1 text-black backdrop-blur-3xl transition-all duration-300 ease-in-out hover:bg-white/20`}
          onClick={toggleExpand}
        >
          <h3 className='select-none pb-2 pt-1 text-xs font-semibold tracking-wide text-text'>
            <PiPushPin className='mr-1 inline-block text-sm' />
            Sabitlenmiş Mesaj
          </h3>
          <div
            className={` ${isExpanded ? 'break-before-all whitespace-normal' : 'truncate'} overflow select-none pb-1 text-text`}
          >
            {processMessage(pinned?.message, true)}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PinnedMessage;
