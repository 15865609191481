import React from "react";
import Image from "next/image";

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-20">
      <div className="relative px-8 py-8">
        <Image
          src="https://d38ezbe56qv4qs.cloudfront.net/img/test/icons/xc-mobile-logo.png"
          alt="loading"
          width={200}
          height={100}
          className="scale-75 opacity-40 animate-ping"
        />
        <Image
          src="https://d38ezbe56qv4qs.cloudfront.net/img/test/icons/xc-mobile-logo.png"
          alt="loading"
          width={200}
          height={100}
          className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        />
      </div>
      {/* <h1>
        <span className="text-xl md:text-3xl animate-pulse text-text">
          Yükleniyor...
        </span>
      </h1> */}
    </div>
  );
};

export default Loading;
