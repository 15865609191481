import React, { useEffect, useState, useContext, useRef } from 'react';
import Link from 'next/link';
import { RiCoinFill } from 'react-icons/ri';
import { TbHexagonLetterN } from 'react-icons/tb';
import { TbHexagonLetterS } from 'react-icons/tb';
import { FaExchangeAlt } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPoints,
  spendPoints,
  setPoints,
  addSipiniyPoints,
  spendSipiniyPoints,
  setSipiniyPoints,
} from '@/helpers/userSlice';
import dynamic from 'next/dynamic';
import SocketContext from '/context/SocketContext';
import 'odometer/themes/odometer-theme-default.css';

const Odometer = dynamic(() => import('react-odometerjs'), { ssr: false });

const CoinDisplay = ({ jackCoin, sipiniyCoin }) => {
  const currentUser = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [coinAmount, setCoinAmount] = useState(jackCoin);
  const [selectedCoin, setSelectedCoin] = useState('JC');
  // const [sipiniyCoinAmount, setSipiniyCoinAmount] = useState(sipiniyCoin);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const socket = useContext(SocketContext);
  const dropdownRef = useRef(null); // Ref to detect outside clicks

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target))
        setIsDropdownOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setCoinAmount(jackCoin);
    // setSipiniyCoinAmount(sipiniyCoin);
    setSelectedCoin('JC');
  }, [jackCoin]);

  useEffect(() => {
    if (!socket) return;

    socket.on('points_added', (data) => {
      if (data.userUID !== currentUser.userUID) return;
      setSelectedCoin('JC');
      localStorage.setItem('selectedCoin', 'JC');
      setCoinAmount((prevAmount) => prevAmount + data.points);
      dispatch(addPoints(data.points));
    });

    socket.on('points_spent', (data) => {
      if (data.userUID !== currentUser.userUID) return;
      setSelectedCoin('JC');
      localStorage.setItem('selectedCoin', 'JC');
      setCoinAmount((prevAmount) => prevAmount - data.points);
      dispatch(spendPoints(data.points));
    });

    socket.on('points_set', (data) => {
      if (data.userUID !== currentUser.userUID) return;
      setSelectedCoin('JC');
      localStorage.setItem('selectedCoin', 'JC');
      setCoinAmount(data.points);
      dispatch(setPoints(data.points));
    });

    // socket.on('sipiniy_points_added', (data) => {
    //   if (data.userUID !== currentUser.userUID) return;
    //   localStorage.setItem('selectedCoin', 'SC');
    //   setSipiniyCoinAmount((prevAmount) => prevAmount + data.points);
    //   dispatch(addSipiniyPoints(data.points));
    // });

    // socket.on('sipiniy_points_spent', (data) => {
    //   if (data.userUID !== currentUser.userUID) return;
    //   setSelectedCoin('SC');
    //   localStorage.setItem('selectedCoin', 'SC');
    //   setSipiniyCoinAmount((prevAmount) => prevAmount - data.points);
    //   dispatch(spendSipiniyPoints(data.points));
    // });

    // socket.on('sipiniy_points_set', (data) => {
    //   if (data.userUID !== currentUser.userUID) return;
    //   setSelectedCoin('SC');
    //   setSipiniyCoinAmount(data.points);
    //   dispatch(setSipiniyPoints(data.points));
    // });

    return () => {
      socket.off('points_added');
      socket.off('points_spent');
      socket.off('points_set');
      // socket.off('sipiniy_points_added');
      // socket.off('sipiniy_points_spent');
      // socket.off('sipiniy_points_set');
    };
  }, [
    socket,
    currentUser?.userUID,
    currentUser?.pointsCurrent,
    currentUser?.pointsSipiniy,
    dispatch,
  ]);

  const dropDown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCoinChange = (coin) => {
    setSelectedCoin(coin);
    localStorage.setItem('selectedCoin', coin);
  };

  return (
    <div
      ref={dropdownRef}
      className={`odometer-font pointer-events-auto relative flex w-40 min-w-max select-none items-center gap-2 rounded-md px-2 before:absolute before:-bottom-2 before:h-[1px] before:w-full before:bg-gradient-to-r before:from-transparent before:to-transparent before:transition-all before:duration-300 before:ease-in-out after:absolute after:-bottom-[79px] after:left-1/2 after:h-24 after:w-24 after:-translate-x-1/2 after:rounded-full after:transition-all after:duration-300 after:ease-in-out ${
        selectedCoin === 'JC'
          ? 'after:bg-main/00 before:via-main'
          : 'before:via-[#a0ff00] after:bg-[#a0ff0000]'
      }`}
    >
      <div
        onClick={(e) => dropDown(e)}
        className='group z-10 flex w-full items-center justify-between hover:cursor-pointer'
      >
        <div className='flex w-full items-center justify-between gap-1 pl-[1px] pr-4'>
          {selectedCoin === 'JC' ? (
            <>
              <TbHexagonLetterN className='h-6 w-6 text-xl text-main' />
              <div>
                <Odometer
                  className='font-base py-1 font-[geist] text-base text-text md:text-lg'
                  value={coinAmount}
                  format='(.ddd)'
                  duration={500}
                />
              </div>
            </>
          ) : (
            <div></div>
            // <>
            //   <TbHexagonLetterS className='h-6 w-6 text-xl text-[#a0ff00]' />
            //   <div>
            //     <Odometer
            //       className='font-base py-1 font-[geist] text-base text-text md:text-lg'
            //       value={sipiniyCoinAmount}
            //       format='(.ddd)'
            //       duration={500}
            //     />
            //   </div>
            // </>
          )}
        </div>
        <div className='flex items-center justify-center'>
          <FaChevronDown
            className={`text-sm transition-transform ${
              isDropdownOpen ? 'rotate-180 text-main' : 'text-text'
            } duration-300 ease-in-out`}
          />
        </div>
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute left-0 top-full z-10 mt-2 w-full rounded-md border bg-navTransparent/90 text-text shadow-md backdrop-blur-xl ${
            selectedCoin === 'JC' ? 'border-main/50' : 'border-[#a0ff0050]'
          }`}
        >
          <div className='mt-2 flex flex-col'>
            <div
              className='flex items-center justify-between gap-1 px-2 py-1 transition-all duration-300 ease-in-out hover:cursor-pointer hover:bg-main/10'
              onClick={() => handleCoinChange('JC')}
            >
              <TbHexagonLetterN className='text-2xl text-main' />
              <div>
                <div
                  className='py-1 text-sm font-semibold text-text'
                  key={coinAmount}
                >
                  {coinAmount?.toLocaleString('tr-TR') || 0}{' '}
                  {/* <span className='text-xs'> JC</span> */}
                </div>
              </div>
            </div>
            {/* <div
              className='flex items-center justify-between gap-1 px-2 py-1 transition-all duration-300 ease-in-out hover:cursor-pointer hover:bg-[#a0ff0010]'
              onClick={() => handleCoinChange('SC')}
            >
              <TbHexagonLetterS className='text-2xl text-[#a0ff00]' />
              <div>
                <div
                  className='py-1 text-sm font-semibold text-text'
                  key={sipiniyCoinAmount}
                >
                  {sipiniyCoinAmount?.toLocaleString('tr-TR') || 0}
                  <span className='text-xs'> SC</span>
                </div>
              </div>
            </div> */}
            <Link
              href={'/coin-exchange'}
              className='mt-3 flex items-center justify-center gap-2 rounded-bl-lg rounded-br-lg border-t border-t-main/10 bg-nav py-1 text-base font-semibold text-text/70 transition-colors duration-300 ease-in-out hover:text-main'
            >
              <FaExchangeAlt />
              Dönüştür
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoinDisplay;
