import { customEmojis } from './emojiUtils'
import Link from 'next/link'
import Image from 'next/image'

// Helper function to process message into components
export const processMessage = (messageText) => {
  const words = messageText.split(/\s+/).map((word, index) => {
    const emoji = customEmojis.find((emoji) => emoji.names.includes(word))
    const link = word.match(/(https?:\/\/[^\s]+)/g)

    if (link) {
      return (
        <Link
          key={index + link[0]}
          href={link[0]}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block align-middle underline underline-offset-1"
        >
          {word}
        </Link>
      )
    }

    if (emoji) {
      return (
        <Image
          key={index}
          src={emoji.imgUrl}
          alt={word}
          className="inline-block align-middle"
          width={22}
          height={22}
        />
      )
    }

    return (
      <span key={index} className="inline">
        {word + ' '}
      </span>
    )
  })

  return words
}
