import React from 'react';
import Logo from './NavbarComponents/Logo';
import Link from 'next/link';
import { RiKickLine } from 'react-icons/ri';
import { FaYoutube } from 'react-icons/fa6';
import { FaXTwitter } from 'react-icons/fa6';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='relative mt-8 bg-nav'>
      <div className='mx-auto flex max-w-[1240px] flex-col py-4 md:py-16'>
        {/* logo */}
        <div className='left-1/2 top-0 mt-6 block self-center md:absolute md:-translate-x-1/2 md:scale-150'>
          <Logo />
        </div>

        {/* container */}
        <div className='grid min-h-max grid-cols-1 justify-items-center px-4 pb-20 md:grid-cols-4 md:pb-0'>
          {/* General */}
          <ul className='mt-6 flex flex-col justify-center gap-1 text-center font-bold'>
            <li className='text-sm text-gray-500'>Genel</li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/'>Anasayfa</Link>
            </li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/sponsorlar'>Sponsorlar</Link>
            </li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/cekilisler'>Çekilişler</Link>
            </li>
            {/* <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/vip'>VIP</Link>
            </li> */}
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/store'>Store</Link>
            </li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/contests'>Tahminler</Link>
            </li>
          </ul>

          {/* Content */}
          <ul className='mt-6 flex flex-col justify-center gap-1 text-center font-bold'>
            <li className='text-sm text-gray-500'>İçerikler</li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/icerikler/bonushunt'>Bonus Hunt</Link>
            </li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/icerikler/bonusduello'>Bonus Duello</Link>
            </li>
            {/* <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/icerikler/bonusturnuva'>Bonus Turnuva</Link>
            </li> */}
          </ul>

          {/* Tournaments */}
          {/* <ul className='mt-6 flex flex-col justify-center gap-1 text-center font-bold'>
            <li className='text-sm text-gray-500'>Turnuvalar</li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/turnuvalar/gamdom/aylik-cevrim-turnuvasi'>
                Aylık Bilet Turnuvası
              </Link>
            </li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/turnuvalar/wanted-001'>Wanted Çarpan Turnuvası</Link>
            </li>
          </ul> */}

          {/* Legal */}
          <ul className='mt-6 flex flex-col justify-center gap-1 text-center font-bold'>
            <li className='text-sm text-gray-500'>Yasal</li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/tos#privacy'>Saklılık Politikası</Link>
            </li>
            <li className='text-sm text-text transition-all duration-300 hover:text-main'>
              <Link href='/tos#tca'>Kullanım Şartları</Link>
            </li>
          </ul>

          {/* Follow us */}
          <ul className='mx-auto mt-6 flex flex-col justify-center gap-1 text-center font-bold md:col-span-full md:mx-0'>
            <li className='text-sm text-gray-500'>Bizi takip et</li>
            <div className='mt-1 flex gap-2'>
              <li>
                <a
                  href='https://kick.com/xcerenays/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <RiKickLine className='h-8 w-8 text-text transition-all duration-300 hover:text-main' />
                </a>
              </li>
              <li>
                <a
                  href='https://xkisa.de/wp'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaWhatsapp className='h-8 w-8 text-text transition-all duration-300 hover:text-main' />
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/xcerenays/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaInstagram className='h-8 w-8 text-text transition-all duration-300 hover:text-main' />
                </a>
              </li>
              <li>
                <a
                  href='https://www.youtube.com/@xCerenaysKesit'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaYoutube className='h-8 w-8 text-text transition-all duration-300 hover:text-main' />
                </a>
              </li>

              <li>
                <a
                  href='https://x.com/xCerenays'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaXTwitter className='h-8 w-8 text-text transition-all duration-300 hover:text-main' />
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
