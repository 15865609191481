import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import dynamic from 'next/dynamic';
import { IoMdSettings, IoIosChatbubbles } from 'react-icons/io';
import {
  FaChevronRight,
  FaFaceSmile,
  FaFaceGrinHearts,
  FaFaceGrinTongue,
  FaFaceAngry,
  FaFaceFrownOpen,
  FaFaceGrinStars,
  FaFaceKiss,
  FaFaceSurprise,
  FaFaceSadTear,
  FaPoop,
  FaFaceSadCry,
  FaFaceGrinBeam,
  FaBomb,
} from 'react-icons/fa6';
import { motion, AnimatePresence } from 'framer-motion';
import MessageBox from './ChatroomComponents/MessageBox';
import ChatSettings from './ChatroomComponents/ChatSettings';
import { customEmojis, convertCustomEmojis } from '../../utils/emojiUtils';
import emojiCategories from '../../utils/emojiCategories';
import useChatScroll from '../../utils/useChatScroll';
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';
import PinnedMessage from './ChatroomComponents/PinnedMessage';
import { toggleChat } from '/helpers/chatSlice';
import { toast } from 'react-toastify';
import { setChatOpen } from '../../helpers/chatSlice';

const EmojiPicker = dynamic(() => import('emoji-picker-react'), { ssr: false });
const MAX_MESSAGES = 100; // Maximum number of messages to keep on the client
const CHARACTER_LIMIT = 500; // Maximum number of characters per message
let socket;

const Chatroom = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [pinnedMessage, setPinnedMessage] = useState([]);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [currentEmoji, setCurrentEmoji] = useState(<FaFaceSmile />);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const contentEditableRef = useRef(null);
  const [characterCount, setCharacterCount] = useState(0);
  const user = useSelector((state) => state.user);
  const [placeholder, setPlaceholder] = useState('Mesajınızı yazın...');
  const dispatch = useDispatch();
  const isChatOpen = useSelector((state) => state.chat.isOpen); // useSelector to get the chat open state

  const emojis = [
    <FaFaceSmile key={0} />,
    <FaFaceGrinHearts key={1} />,
    <FaFaceGrinTongue key={2} />,
    <FaFaceAngry key={3} />,
    <FaFaceFrownOpen key={4} />,
    <FaFaceGrinStars key={5} />,
    <FaFaceKiss key={6} />,
    <FaFaceSurprise key={7} />,
    <FaFaceSadTear key={8} />,
    <FaPoop key={9} />,
    <FaFaceSadCry key={12} />,
    <FaFaceGrinBeam key={13} />,
    <FaBomb key={14} />,
  ];

  const { chatRef, handleScroll, scrollToBottom, newMessages } =
    useChatScroll(messages);

  // Ensure you handle the reception of messages from the server
  useEffect(() => {
    socket = io(`${process.env.NEXT_PUBLIC_API_URL}`, {
      transports: ['websocket', 'polling'],
    });

    socket.on('init_messages', (initialMessages) => {
      setMessages(initialMessages.reverse());
      // setPinnedMessage to .isPinned === true message
      setPinnedMessage(initialMessages.find((message) => message.isPinned));
    });

    socket.on('receive_message', (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    socket.on('user_chat_banned', () => {
      setCanSendMessage(false);
      setPlaceholder('Yasaklısınız.');
    });

    socket.on('user_banned', () => {
      setCanSendMessage(false);
      setPlaceholder('Yasaklısınız.');
    });

    socket.on('message_ignored', (data) => {
      if (data.reason === 'rate_limit') {
        // Handle rate limit
        return;
      }
    });

    return () => {
      socket.off('init_messages');
      socket.off('receive_message');
      socket.off('user_banned');
      socket.off('message_ignored');
    };
  }, [scrollToBottom]);

  useEffect(() => {
    if (user.username) {
      setPlaceholder('Mesajınızı yazın...');
    } else {
      setPlaceholder('Giriş yapmanız gerekmektedir.');
    }
  }, [user.username]);

  const escapeHtml = (text) => {
    return text.replace(
      /[&<>"']/g,
      (char) =>
        ({
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#39;',
        })[char]
    );
  };

  useEffect(() => {
    const handlePaste = (event) => {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      const sanitizedData = escapeHtml(pastedData); // Escape HTML characters
      document.execCommand('insertHTML', false, sanitizedData);
    };

    const contentEditableElement = contentEditableRef.current;
    if (contentEditableElement) {
      contentEditableElement.addEventListener('paste', handlePaste);
    }

    return () => {
      if (contentEditableElement) {
        contentEditableElement.removeEventListener('paste', handlePaste);
      }
    };
  }, [isChatOpen]);

  // when the chat is closed, reset the message input
  useEffect(() => {
    setMessage('');
  }, [isChatOpen]);

  // when the chat is opened scroll to the bottom
  useEffect(() => {
    if (isChatOpen) {
      scrollToBottom();
    }
  }, [isChatOpen, scrollToBottom]);

  const handleSendMessage = () => {
    if (emojiPickerOpen) {
      setEmojiPickerOpen(false);
    }
    if (message.trim() !== '') {
      const content = contentEditableRef.current.innerHTML;
      const sanitizedMessage = DOMPurify.sanitize(content);
      const parsedMessage = parseMessage(sanitizedMessage);

      const newMessage = {
        username: user.username,
        userUID: user.userUID,
        level: user.level,
        message: parsedMessage,
        timestamp: new Date(),
      };

      // Emit the message to the server
      socket.emit('send_message', newMessage);

      // Clear the message input and reset the state
      setMessage('');
      contentEditableRef.current.innerHTML = '';
    }
  };

  const handleInputChange = (e) => {
    const contentEditable = contentEditableRef.current;
    const selection = window.getSelection();

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(contentEditable);
    preCaretRange.setEnd(range.startContainer, range.startOffset);
    const startCaretOffset = preCaretRange.toString().length;
    const sanitizedContent = DOMPurify.sanitize(contentEditable.innerHTML);
    const convertedContent = convertCustomEmojis(sanitizedContent);

    contentEditable.innerHTML = convertedContent;
    setCharacterCount(convertedContent.length);
    if (convertedContent.length > CHARACTER_LIMIT) {
      contentEditable.innerHTML = convertedContent.slice(0, CHARACTER_LIMIT);
      setCharacterCount(CHARACTER_LIMIT);
    }

    setMessage(convertedContent);
    contentEditable.focus();

    const newRange = document.createRange();
    const newSelection = window.getSelection();

    let charIndex = 0;
    let foundStart = false;
    let foundEnd = false;

    function restoreCursorPosition(node) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;
        if (
          !foundStart &&
          startCaretOffset >= charIndex &&
          startCaretOffset <= nextCharIndex
        ) {
          newRange.setStart(node, startCaretOffset - charIndex);
          foundStart = true;
        }
        if (
          foundStart &&
          startCaretOffset >= charIndex &&
          startCaretOffset <= nextCharIndex
        ) {
          newRange.setEnd(node, startCaretOffset - charIndex);
          foundEnd = true;
        }
        charIndex = nextCharIndex;
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          restoreCursorPosition(node.childNodes[i]);
          if (foundEnd) {
            break;
          }
        }
      }
    }

    restoreCursorPosition(contentEditable);

    if (!foundEnd) {
      newRange.setStart(contentEditable, contentEditable.childNodes.length);
      newRange.setEnd(contentEditable, contentEditable.childNodes.length);
    }

    newSelection.removeAllRanges();
    newSelection.addRange(newRange);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleEmojiSelect = (emojiData, emoji) => {
    let imgTag;
    if (emojiData.isCustom) {
      imgTag = convertCustomEmojis(emojiData.emoji);
    } else {
      imgTag = emojiData.emoji;
    }
    moveCursorToEnd();
    insertAtCaret(imgTag, contentEditableRef.current);
  };

  const moveCursorToEnd = () => {
    const sel = window.getSelection();
    const range = document.createRange();
    sel.removeAllRanges();
    range.selectNodeContents(contentEditableRef.current);
    range.collapse(false);
    sel.addRange(range);
    contentEditableRef.current.focus();
  };

  const insertAtCaret = (html, element) => {
    const range = document.createRange();
    const sel = window.getSelection();
    element.focus();
    if (sel.rangeCount > 0 && element.contains(sel.anchorNode)) {
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.deleteContents();
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      const frag = document.createDocumentFragment();
      let node, lastNode;
      while ((node = tempDiv.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range.setStartAfter(lastNode);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    } else {
      element.innerHTML += html;
    }
    const sanitizedContent = DOMPurify.sanitize(element.innerHTML);
    setMessage(sanitizedContent);
  };

  const parseMessage = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const nodes = tempDiv.childNodes;
    let parsedMessage = '';

    nodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        parsedMessage += node.textContent;
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName === 'IMG'
      ) {
        const emojiId = node.getAttribute('data-emoji-id');
        parsedMessage += ` ${emojiId} `;
      }
    });

    return parsedMessage.trim();
  };

  return (
    <AnimatePresence>
      {isChatOpen && (
        <motion.div
          className='fixed -right-2 top-0 z-[1000] mx-2 h-[calc(100dvh-3rem)] max-w-full shrink-0 rounded-lg bg-bg-new p-2 pr-1 md:right-0 md:top-16 md:mx-0 md:block md:h-[calc(98dvh-3.5rem)] md:max-w-[320px] 2xl:sticky 2xl:top-2 2xl:z-0 2xl:h-[98dvh]'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, maxWidth: 0, overflow: 'hidden' }}
          transition={{
            opacity: { duration: 0.4 },
          }}
        >
          <div className='flex h-full flex-col'>
            <div className='relative'>
              <div className='mb-2 flex items-center gap-2 border-b-2 border-background border-opacity-40 pb-6'>
                <FaChevronRight
                  className='bg- -ml-[18px] -mt-3 hidden h-8 w-8 cursor-pointer rounded-lg border-4 border-background bg-bgSoft p-[7px] text-3xl text-text/60 transition-all duration-500 hover:text-text md:block'
                  onClick={() => {
                    dispatch(toggleChat());
                    setEmojiPickerOpen(false);
                    setIsSettingsOpen(false);
                  }}
                />
                <IoIosChatbubbles className='text-2xl text-accent' />
                <p className='text-sm font-semibold text-text'>Chat</p>
              </div>
              <PinnedMessage pinned={pinnedMessage} />
            </div>
            <div
              ref={chatRef}
              onScroll={handleScroll}
              className='flex-1 overflow-y-auto overflow-x-hidden'
            >
              {messages.map((msg, index) => (
                <MessageBox key={msg._id} message={msg} />
              ))}
              {newMessages > 0 && (
                <div>
                  <div
                    className='absolute bottom-32 left-1/2 -translate-x-1/2 transform cursor-pointer rounded-md bg-main/10 px-3 py-2 text-center text-xs font-semibold leading-5 text-white backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-105 hover:backdrop-blur-xl'
                    onClick={scrollToBottom}
                  >
                    {newMessages} Yeni Mesaj
                  </div>
                  <p className='text-sm font-semibold text-text'>
                    {msg.message}
                  </p>
                </div>
              )}
            </div>
            {isChatOpen && (
              <EmojiPicker
                width={'100%'}
                height={500}
                theme='dark'
                open={emojiPickerOpen}
                className=''
                onClose={() => setEmojiPickerOpen(false)}
                onEmojiClick={handleEmojiSelect}
                emojiStyle='native'
                skinTonesDisabled={true}
                previewConfig={{
                  showPreview: false,
                }}
                suggestedEmojisMode='recent'
                searchPlaceHolder='Ara'
                customEmojis={customEmojis}
                categories={emojiCategories}
              />
            )}
            <div className='relative flex flex-col items-center border-t border-background border-opacity-40 pt-2'>
              <ChatSettings
                isSettingsOpen={isSettingsOpen}
                setIsSettingsOpen={setIsSettingsOpen}
                user={user}
              />

              <div className='flex w-full flex-col gap-2'>
                <div className='relative flex-1'>
                  {user.username ? (
                    <div
                      ref={contentEditableRef}
                      contentEditable
                      onInput={handleInputChange}
                      onKeyDown={handleKeyDown}
                      className='chat-input'
                      id='chatroom-input'
                      data-placeholder={placeholder}
                      aria-disabled={!user.username}
                      spellCheck='false'
                    />
                  ) : (
                    <div
                      ref={contentEditableRef}
                      onInput={handleInputChange}
                      onKeyDown={handleKeyDown}
                      className='chat-input'
                      id='chatroom-input'
                      data-placeholder={placeholder}
                      aria-disabled={!user.username}
                      spellCheck='false'
                    />
                  )}
                  <div
                    onClick={() => {
                      if (user.username) {
                        setEmojiPickerOpen(!emojiPickerOpen);
                      } else {
                        toast.error('Giriş yapmanız gerekmektedir.');
                      }
                    }}
                    onMouseLeave={() =>
                      setCurrentEmoji(
                        emojis[Math.floor(Math.random() * emojis.length)]
                      )
                    }
                    className='absolute right-3 top-1/2 -translate-y-2/3 transform cursor-pointer text-xl text-text/60 transition-all duration-300 ease-in-out hover:scale-105 hover:text-yellow-400'
                  >
                    {currentEmoji}
                  </div>
                </div>
                <div className='flex items-center gap-2 self-end'>
                  <IoMdSettings
                    className='cursor-pointer text-xl text-text/60 transition-all duration-500 hover:rotate-180 hover:text-text'
                    onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                  />
                  <button
                    onClick={handleSendMessage}
                    className={`buttonGradientBlue ${!user.username ? 'cursor-not-allowed opacity-20' : ''}`}
                    disabled={!user.username}
                  >
                    Gönder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Chatroom;
